/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoImagesOutline } from 'react-icons/io5';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import FormBlock from '../../../../components/FormBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
// SERVICES AND HELPERS
import * as galleryService from '../../../../services/management/galleryService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const initialGalleryModel = {
  id: 0,
  isActive: true,
  name: '',
};

const GalleryListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [isAddGalleryModalOpen, setIsAddGalleryModalOpen] = useState(false);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchGalleries();
  }, []);

  const fetchGalleries = () => {
    setIsLoading(true);
    galleryService.getAllGalleries().then((res) => {
      setGalleries(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: initialGalleryModel,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      name: Yup.string().required('Name is required').max(250),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      createGallery(values);
    },
  });

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => history.push(`/cms/management/gallery/${galleries[dataIndex].id}`)}
            />

            {
              auth.isAdmin
              && (
                <Button
                  text="Delete"
                  className="danger--bg ml-5"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Remove Gallery',
                      text: 'Are you sure you want to remove this image gallery?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteGallery(galleries[dataIndex].id);
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Gallery Name',
      options: columnOptions,
    },
    {
      name: 'galleryImages',
      label: 'Image Count',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const imageCount = galleries[dataIndex].galleryImages.length;
          return <p>{imageCount}</p>;
        }
      },
    },
    {
      name: 'isActive',
      label: 'Is Active',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = galleries[dataIndex];
          return <p>{isActive ? 'Yes' : 'No'}</p>;
        }
      },
    }
  ];

  const createGallery = (model) => {
    setIsLoading(true);
    galleryService.createGallery(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      toggleAddGalleryModal();
    });
  };

  const deleteGallery = (id) => {
    setIsLoading(true);
    galleryService.deleteGallery(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchGalleries();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const toggleAddGalleryModal = () => setIsAddGalleryModalOpen((prevState) => !prevState);

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Galleries"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Gallery' : ''}
                primaryButtonIconLeft={<IoImagesOutline className={iconColor} size={iconSize} />}
                primaryButtonOnClick={toggleAddGalleryModal}
              />

              <CustomBlock>
                <MUIDataTable
                  data={galleries}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* ADD GALLERY MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isAddGalleryModalOpen}
        size="md"
        contentHeader="Add Gallery"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.handleSubmit}
        onHide={toggleAddGalleryModal}
      >
        <FormBlock onSubmit={formik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                isRequired
                label="Name"
                placeholder="e.g. Historic Photos"
                errorMessage={formik.errors.name}
                inputState={`${helper.getInputClasses(formik, 'name')}`}
                {...formik.getFieldProps('name')}
              />
            </ContentBlock>

            <ContentBlock>
              <CheckboxBlock
                label="Is Gallery Active?"
                id="isActive"
                {...formik.getFieldProps('isActive')}
                isChecked={formik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions
})(GalleryListingPage);