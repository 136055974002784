import React from 'react';
import { Redirect } from 'react-router-dom';
// ROUTES
import CMSRoute from './CMSRoute';

const MemberRoute = (props) => {
  const { hasAccess, accessLevel } = props;
  let redirectPath;

  if (!hasAccess) {
    if (accessLevel === 0) {
      redirectPath = '/register-user';
    } else if (accessLevel === '30') {
      redirectPath = '/account-details';
    } else if (accessLevel >= '40') {
      redirectPath = '/admin/management/users';
    }
  }

  return (
    hasAccess ? (
      <CMSRoute {...props} />
    ) : (
      <Redirect to={redirectPath} />
    )
  );
};

export default MemberRoute;