/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoImageOutline } from 'react-icons/io5';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
import ModalBlock from '../../../../components/ModalBlock';
import Button from '../../../../components/Button';
import Image from '../../../../components/Image';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as galleryService from '../../../../services/management/galleryService';
import * as galleryImageService from '../../../../services/management/galleryImageService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const initialGalleryModel = {
  id: 0,
  isActive: true,
  name: '',
};

const initialGalleryImageModel = {
  id: 0,
  galleryId: 0,
  title: '',
  subtitle: '',
  imageFilePath: '',
};

const GalleryManagementPage = (props) => {
  const {
    history,
    showAlert,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const { galleryId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState(initialGalleryModel);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImage, setGalleryImage] = useState(initialGalleryImageModel);
  const [file, setFile] = useState([]);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    if (galleryId) {
      fetchGallery();
    }
  }, []);

  const fetchGallery = () => {
    setIsLoading(true);
    galleryService.getGalleryById(galleryId).then((res) => {
      setGallery(res);
      setGalleryImages(res.galleryImages);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: gallery,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      name: Yup.string().required('Gallery name is required').max(250),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateGallery(values);
    },
  });

  const formikGalleryImage = useFormik({
    initialValues: galleryImage,
    validationSchema: Yup.object().shape({ subtitle: Yup.string().required('Image caption is required').max(250) }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!galleryImage.id) {
        addImage(values);
      } else {
        updateImage(values);
      }
    },
  });

  const updateGallery = (model) => {
    setIsLoading(true);

    galleryService.updateGallery(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/galleries');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addImage = (model) => {
    setIsLoading(true);
    setGalleryImage(initialGalleryImageModel);

    if (file.length > 0) {
      model = { ...model, image: file.length > 0 ? file[0].file : null, galleryId };
    }

    galleryImageService.addImage(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchGallery();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      toggleAddImageModal();
    });
  };

  const updateImage = (model) => {
    setIsLoading(true);

    galleryImageService.updateImage(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchGallery();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      toggleAddImageModal();
    });
  };

  const deleteImage = (imageId) => {
    setIsLoading(true);
    galleryImageService.deleteImage(imageId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchGallery();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      // history.push(`/cms/management/gallery/${res.id}`);
      hideConfirmModal();
    });
  };

  const toggleAddImageModal = () => setIsAddImageModalOpen((prevState) => !prevState);

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                setGalleryImage(galleryImages[dataIndex]);
                toggleAddImageModal();
              }}
            />
            <Button
              text="Delete"
              className="danger--bg ml-5"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Image',
                  text: 'Are you sure you want to remove this image?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteImage(galleryImages[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'imageUrl',
      label: 'Preview',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = galleryImages[dataIndex].imageFilePath;
          return (
            <CustomBlock className="image" style={{ background: `url(${process.env.REACT_APP_API_URL}Attachments${imageUrl})  no-repeat center/cover`, width: '140px', height: '70px' }} />
          );
        }
      },
    },
    {
      name: 'subtitle',
      label: 'Image Caption',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>

              {/* BASIC INFORMATION */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={galleryId ? 'Gallery Details' : 'New Gallery'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    isRequired
                    label="Name"
                    placeholder="e.g. Historic Photos"
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock className="mb-15">
                  <CheckboxBlock
                    label="Is Gallery Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>

              {/* IMAGES */}
              {galleryId && (
                <Section
                  hasNoContainer
                  className="content-container--card-style--with-shadow mt-30 mb-120"
                >
                  <ContentBlock>
                    <ContentHeader
                      title="Images"
                      headerSize="md"
                      className="alt-font fw-600 secondary--clr pb-15"
                      primaryButtonText="Add Image"
                      primaryButtonIconLeft={(
                        <IoImageOutline
                          className={iconColor}
                          size={iconSize}
                        />
                      )}
                      primaryButtonOnClick={() => {
                        setGalleryImage(initialGalleryImageModel);
                        toggleAddImageModal();
                      }}
                    />
                  </ContentBlock>

                  {galleryImages.length > 0 && (
                    <CustomBlock>
                      <MUIDataTable
                        data={galleryImages}
                        columns={columns}
                        options={{
                          selectableRows: 'none',
                          download: true,
                          print: false,
                          jumpToPage: true,
                          textLabels: { pagination: { jumpToPage: 'Page No:' } },
                          rowsPerPage: 25,
                        }}
                      />
                    </CustomBlock>
                  )}
                </Section>
              )}

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={galleryId > 0 ? 'Save Changes' : 'Create Gallery'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/galleries"
                optionalActionText={galleryId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/galleries"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* ADD IMAGE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isAddImageModalOpen}
        size="md"
        contentHeader={galleryImage.id ? 'Update Image' : 'Add Image'}
        primaryModalActionText={galleryImage.id ? 'Update' : 'Add'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formikGalleryImage.handleSubmit}
        onHide={toggleAddImageModal}
      >
        <FormBlock onSubmit={formikGalleryImage.submitForm}>
          <ContentBlock>
            <TextAreaBlock
              label="Image Caption"
              placeholder="Add a caption to give context to this image"
              errorMessage={formikGalleryImage.errors.subtitle}
              inputState={`${helper.getInputClasses(formikGalleryImage, 'subtitle')}`}
              {...formikGalleryImage.getFieldProps('subtitle')}
            />
          </ContentBlock>

          <ContentBlock className="mt-30">
            {galleryImage.id ? (
              <>
                <ContentHeader
                  title="Gallery Image"
                  headerSize="md"
                  className="alt-font fw-600 secondary--clr pb-15"
                />
                <CustomBlock className="flex-center pt-15 pb-30">
                  <Image
                    source={`${process.env.REACT_APP_API_URL}Attachments/${formikGalleryImage.values.imageFilePath}`}
                    className="event-image pl-10 pr-10"
                  />
                </CustomBlock>
              </>
            ) : (
              <>
                <ContentHeader
                  title="Upload Gallery Image"
                  subtitle="Upload an image for this gallery"
                  headerSize="md"
                  subtitleSize="md"
                  className="alt-font fw-600 secondary--clr pb-15"
                />
                <ContentBlock className="mt-15">
                  <FileUploadBlock onupdatefiles={setFile} />
                </ContentBlock>
              </>
            )}
          </ContentBlock>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(GalleryManagementPage);