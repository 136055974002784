/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import Section from '../../../components/Section';
import ContentSection from '../../../components/ContentSection';
// CMS COMPONENTS
// ASSETS - GALLERY PAGE
// SERVICES AND HELPERS
import * as galleryService from '../../../services/management/galleryService';
import * as alert from '../../../redux/alertToastRedux';

const GalleryPage = (props) => {
  const { showAlert, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const bannerImage = 'https://images.pexels.com/photos/296649/pexels-photo-296649.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

  useEffect(() => {
    fetchGalleries();
  }, []);

  const fetchGalleries = () => {
    setIsLoading(true);
    galleryService.getAllGalleries().then((res) => {
      setGalleries(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Photo Galleries"
        isDynamicBanner
        imageSource={bannerImage}
      />

      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <Section>
            {galleries && (
              galleries.map((gallery) => (
                <ContentSection
                  title={gallery.name}
                  titleColor="white--clr"
                  // imageSource="https://source.unsplash.com/random"
                  imageSource={gallery.galleryImages && gallery.galleryImages.length > 0 && `${process.env.REACT_APP_API_URL}Attachments${gallery.galleryImages[0].imageFilePath}`}
                  description=""
                  hasRoundCorners
                  isFullWidthUnpadded
                  primaryActionText="View Gallery"
                  primaryActionOnClick={() => history.push(`/gallery-images/${gallery.id}`)}
                  isCentered
                  isFloatingCard
                  hasCardBackgroundImage
                />
              ))
            )}

          </Section>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions })(GalleryPage);