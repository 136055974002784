/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
import Button from '../../../../components/Button';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
// CMS COMPONENTS
// ASSETS - DONTATIONS TAB
// SERVICES AND HELPERS
import * as donationService from '../../../../services/management/donationService';
import * as helper from '../../../../helpers/helper';

const DontationsTab = (props) => {
  const { history, setIsLoading } = props;
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    getUserDonations();
  }, []);

  const getUserDonations = () => {
    setIsLoading(true);

    donationService.getDonations()
      .then((res) => {
        setDonations(res);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ContentBlock className="profile-item-details mt-30">
        <CustomBlock className="header">
          <h4>Donation History</h4>
        </CustomBlock>

        <CustomBlock className="body">
          {donations.length === 0 ? (
            <ContentHeader
              subtitle="No donations found."
              subtitleSize="md"
              className="alt-font fw-700 primary--clr m-0 p-0"
            />
          ) : (
            <Table
              striped
              borderless
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <h5 className="text-header--xs pb-0">Item</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Date Ordered</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Amount</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Payment Type</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{moment(donation.dateOrdered).format('MMMM D, YYYY')}</td>
                    <td>{helper.currencyFormat(donation.amount)}</td>
                    <td>{donation.paymentType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CustomBlock>
      </ContentBlock>

      <CustomBlock className="content-container--actions">
        <Button
          text="Donate Now"
          className="secondary--bg"
          size="sm"
          onClick={() => {
            history.push('/donation-form');
          }}
        />
      </CustomBlock>
    </>
  );
};

export default DontationsTab;