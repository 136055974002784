import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Brick/';

const getBricks = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetBricks`, constants.REQUIRE_INTERCEPTORS);

const getAllBricks = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllBricks`, constants.REQUIRE_INTERCEPTORS);

const submitBrick = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SubmitBrick`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getBricks,
  getAllBricks,
  submitBrick
};