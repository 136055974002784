/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
// DEPENDENCIES
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { FiLock, FiUser } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Button from './Button';
import InputBlock from './InputBlock';
import ModalBlock from './ModalBlock';
// SERVICES AND HELPERS
import * as userService from '../services/management/userService';
import * as helper from '../helpers/helper';

const LoginPasswordRecoveryModal = (props) => {
  const {
    showAlert,
    history,
    login,
    iconSize,
    onLoginSuccess,
    isVisible,
    setIsVisible,
  } = props;
  // LOGIN
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  // RECOVER ACCOUNT
  const [recoverAccountPanel, setRecoverAccountPanel] = useState(false);
  // RESET PASSWORD
  const [resetPasswordPanel, setResetPasswordPanel] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [resetPasswordConfirmVisible, setResetPasswordConfirmVisible] = useState(false);

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Invalid email format'),
      password: Yup.string().required('Password is required').min(8, 'Minimum password length must be at least 8 characters'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      userService.login(values).then((res) => {
        const decodedToken = jwt_decode(res.token);
        login(res);
        if (onLoginSuccess) onLoginSuccess(decodedToken);
        if (decodedToken.AccessLevel >= 40) {
          history.push('/admin/management/users');
        } else {
          history.push({
            pathname: '/account-details',
            state: { userId: decodedToken.UserId },
          });
        }
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const recoveryEmailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Invalid email format'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      userService.sendPasswordRecoveryEmail(values.email).then((res) => {
        showAlert({ text: 'Password recovery email sent.', state: 'success' });
        setRecoverAccountPanel(false);
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      resetCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      resetCode: Yup.string().required('Reset code is required'),
      newPassword: Yup.string().required('New password is required').min(8, 'Minimum password length must be at least 8 characters'),
      confirmPassword: Yup.string().required('Confirm your new password').oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await userService.resetPassword({
          email: values.email,
          resetCode: values.resetCode,
          newPassword: values.newPassword,
        });
        showAlert({ text: 'Password reset successful.', state: 'success' });
        setResetPasswordPanel(false);
      } catch (ex) {
        setAlert({ text: ex.response?.data?.message || ex.message, state: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleLoginModalClose = () => {
    setIsVisible(false);
    setRecoverAccountPanel(false);
    setResetPasswordPanel(false);
  };

  const handleSecondaryActionClick = () => {
    if (resetPasswordPanel) {
      setResetPasswordPanel(false);
      setRecoverAccountPanel(true);
    } else if (recoverAccountPanel) {
      setRecoverAccountPanel(false);
    } else {
      handleLoginModalClose();
    }
  };

  const handleResetPasswordPanelView = () => {
    setRecoverAccountPanel(false);
    setResetPasswordPanel(true);
  };

  return (
    <ModalBlock
      hasCloseAction
      isVisible={isVisible}
      size="md"
      contentHeader={!recoverAccountPanel && !resetPasswordPanel ? 'Member Login' : resetPasswordPanel ? 'Reset Password' : 'Recover Account'}
      primaryModalActionText={!recoverAccountPanel && !resetPasswordPanel ? 'Login' : resetPasswordPanel ? 'Submit' : 'Send Recovery Code'}
      primaryModalActionType={!recoverAccountPanel && !resetPasswordPanel ? 'submit' : ''}
      primaryModalActionColor="primary--bg"
      primaryModalActionOnClick={!recoverAccountPanel && !resetPasswordPanel ? loginFormik.handleSubmit : resetPasswordPanel ? resetPasswordFormik.handleSubmit : recoveryEmailFormik.handleSubmit}
      secondaryModalActionText={!recoverAccountPanel && !resetPasswordPanel ? 'Cancel' : 'Back'}
      secondaryModalActionColor="danger--bg"
      secondaryModalActionOnClick={handleSecondaryActionClick}
      onHide={handleLoginModalClose}
      isEnterKeydownEnabled
      isLoading={loginFormik.isSubmitting || resetPasswordFormik.isSubmitting || recoveryEmailFormik.isSubmitting}
    >
      {
        // LOGIN PANEL
        !recoverAccountPanel && !resetPasswordPanel
          ? (
            <>
              <form onSubmit={loginFormik.handleSubmit}>
                <InputBlock
                  id="email"
                  label="Email"
                  placeholder="Enter email"
                  errorMessage={loginFormik.errors.email}
                  inputState={`${helper.getInputClasses(loginFormik, 'email')}`}
                  {...loginFormik.getFieldProps('email')}
                  iconLeft={<FiUser size={iconSize} className="dark-grey--clr" />}
                  className="input-block light--sbg padded"
                />
                <InputBlock
                  type={loginPasswordVisible ? 'text' : 'password'}
                  label="Password"
                  placeholder="Enter password"
                  iconLeft={<FiLock size={iconSize} className="dark-grey--clr" />}
                  inputRightButtonText={loginPasswordVisible ? 'Hide' : 'Show'}
                  inputRightButtonOnClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                  {...loginFormik.getFieldProps('password')}
                  errorMessage={loginFormik.errors.password}
                  inputState={`${helper.getInputClasses(loginFormik, 'password')}`}
                  className="input-block light--sbg padded"
                />
              </form>

              <div className="d-flex flex-column align-items-start">
                <Button
                  type="link"
                  text={<p>Not a member? <span className="fw-700"> Create Account</span></p>}
                  className="animate-onhover-primary dark--clr p-0"
                  onClick={() => {
                    history.push('/register-user');
                    handleLoginModalClose();
                  }}
                />
                <Button
                  text="Forgot Password?"
                  className="animate-onhover-primary dark--clr p-0"
                  onClick={() => setRecoverAccountPanel(true)}
                />
              </div>
            </>
          )
          // RECOVER ACCOUNT PANEL
          : recoverAccountPanel
            ? (
              <>
                <div className="d-flex flex-column align-items-start mb-30">
                  <p className="mid-grey--clr">
                    Enter your membership email to receive a reset code. Use the code and the link below to reset your password.
                  </p>
                  <Button
                    text={<p>Already have a reset code? <span className="fw-700"> Reset Password</span></p>}
                    className="animate-onhover-primary dark--clr p-0"
                    onClick={() => handleResetPasswordPanelView()}
                  />
                </div>

                <form onSubmit={recoveryEmailFormik.handleSubmit}>
                  <InputBlock
                    id="email"
                    label="Email Address"
                    placeholder="Enter your email address"
                    errorMessage={recoveryEmailFormik.errors.email}
                    inputState={`${helper.getInputClasses(recoveryEmailFormik, 'email')}`}
                    {...recoveryEmailFormik.getFieldProps('email')}
                    iconLeft={<HiOutlineMail size={iconSize} className="dark-grey--clr" />}
                  />
                </form>
              </>
            )
            : (
              // RESET PASSWORD PANEL
              <form onSubmit={resetPasswordFormik.handleSubmit}>
                <InputBlock
                  label="Reset Code"
                  type="number"
                  placeholder="Enter your reset code"
                  errorMessage={resetPasswordFormik.errors.resetCode}
                  inputState={`${helper.getInputClasses(resetPasswordFormik, 'resetCode')}`}
                  {...resetPasswordFormik.getFieldProps('resetCode')}
                />
                <InputBlock
                  label="New Password"
                  type={resetPasswordVisible ? 'text' : 'password'}
                  placeholder="Enter new password"
                  inputRightButtonText={resetPasswordVisible ? 'Hide' : 'Show'}
                  inputRightButtonOnClick={() => setResetPasswordVisible(!resetPasswordVisible)}
                  errorMessage={resetPasswordFormik.errors.newPassword}
                  inputState={`${helper.getInputClasses(resetPasswordFormik, 'newPassword')}`}
                  {...resetPasswordFormik.getFieldProps('newPassword')}
                />
                <InputBlock
                  label="Confirm Password"
                  type={resetPasswordConfirmVisible ? 'text' : 'password'}
                  placeholder="Confirm new password"
                  inputRightButtonText={resetPasswordConfirmVisible ? 'Hide' : 'Show'}
                  inputRightButtonOnClick={() => setResetPasswordConfirmVisible(!resetPasswordConfirmVisible)}
                  errorMessage={resetPasswordFormik.errors.confirmPassword}
                  inputState={`${helper.getInputClasses(resetPasswordFormik, 'confirmPassword')}`}
                  {...resetPasswordFormik.getFieldProps('confirmPassword')}
                />
              </form>
            )
      }
    </ModalBlock>
  );
};

export default LoginPasswordRecoveryModal;