/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// COMPONENTS
// ICONS
import { FaRegFilePdf } from 'react-icons/fa';
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import CustomBlock from '../../../components/CustomBlock';
import ContentSection from '../../../components/ContentSection';
import ContentBlock from '../../../components/ContentBlock';
import TextBlock from '../../../components/TextBlock';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
// CMS COMPONENTS
// ASSETS - NEWSLETTER PAGE
// SERVICES AND HELPERS
import * as newsletterService from '../../../services/management/newsletterService';
import * as alert from '../../../redux/alertToastRedux';

const NewsletterPage = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [newsletters, setNewsletters] = useState([]);
  const bannerImage = 'https://images.pexels.com/photos/518543/pexels-photo-518543.jpeg';

  useEffect(() => {
    fetchNewsletters();
  }, []);

  const fetchNewsletters = () => {
    setIsLoading(true);
    newsletterService.getAllNewsletters().then((res) => {
      const sortedNewsletters = res.sort((a, b) => {
        const aTimestamp = moment(`${a.year}-${a.month}`, 'YYYY-MMMM').unix();
        const bTimestamp = moment(`${b.year}-${b.month}`, 'YYYY-MMMM').unix();

        return aTimestamp - bTimestamp;
      });

      const groupedNewsletters = sortedNewsletters.reduce((acc, newsletter) => {
        const { year } = newsletter;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(newsletter);
        return acc;
      }, {});

      setNewsletters(groupedNewsletters);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Newsletters"
        isDynamicBanner
        imageSource={bannerImage}
      />

      <ContentSection
        isTextOnly
        title="Hungarian News and Updates"
        description="Dive into our collection of monthly newsletters to explore a variety of topics, such as the President's Message, museum updates, upcoming community events, and much more! Stay connected and informed while celebrating our rich heritage through these captivating newsletters, perfect for anyone interested in Hungarian history and culture."
        descriptionColor="dark-clr"
      />

      <ContentBlock className="content-section newsletters-container pt-0 pb-100">
        {newsletters && (
          Object.keys(newsletters).sort((a, b) => b.localeCompare(a)).map((year) => (
            <CustomBlock className="content-container">
              <TextBlock
                text={year}
                type="header"
                color="primary--clr"
                position="left"
                className="pb-10"
              />
              <CustomBlock className="newsletter-group">
                {newsletters[year].map((newsletter) => (
                  <Button
                    type="button"
                    className="primary--bg round-corners"
                    text={`${newsletter.month} ${newsletter.name}`}
                    size="md"
                    iconLeft={<FaRegFilePdf />}
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_API_URL}Attachments${newsletter.documentFilePath}`, '_blank');
                    }}
                  />
                ))}
              </CustomBlock>
            </CustomBlock>
          ))
        )}
      </ContentBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions })(NewsletterPage);