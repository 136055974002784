/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PayPalButton } from 'react-paypal-button-v2';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentSection from '../../../components/ContentSection';
import TextBlock from '../../../components/TextBlock';
import FormBlock from '../../../components/FormBlock';
import InputBlock from '../../../components/InputBlock';
import ModalBlock from '../../../components/ModalBlock';
import Overlay from '../../../components/Overlay';
import Section from '../../../components/Section';
// CMS COMPONENTS
// ASSETS - DONATION PAGE
import BannerImage from '../../../assets/img/banner-image_donation.png';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import * as donationService from '../../../services/management/donationService';
import * as alert from '../../../redux/alertToastRedux';
import constants from '../../../constants/constants';

const donationOptions = [
  { value: 10, label: '$10' },
  { value: 25, label: '$25' },
  { value: 50, label: '$50' },
  { value: 100, label: '$100' },
  { value: '', label: 'Other' },
];

const initialModel = {
  amount: '',
  payPalTransactionId: ''
};

const DonationPage = (props) => {
  const { showAlert, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model] = useState(initialModel);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isPaymentByCheck, setIsPaymentByCheck] = useState(false);
  const [isPaymentByCheckSection, setIsPaymentByCheckSection] = useState(true);
  const [isPaymentByCheckInstructions, setIsPaymentByCheckInstructions] = useState(false);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      amount: Yup.number().required('Please provide a donation amount.').min(1),
      payPalTransactionId: Yup.string().nullable().max(100),
    }),
    enableReinitialize: true,
    onSubmit: () => setIsPaymentModalOpen(true),
  });

  const submitForm = (values, payPalTransactionId) => {
    setIsLoading(true);
    donationService.submitDonation({
      ...values,
      payPalTransactionId
    })
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
        history.push('/account-details');
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Donations"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="content-wrapper light-grey--sbg">
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Donations Form"
          description="Contribute to the preservation of Hungarian Heritage and the Hungarian Settlement Museum by visiting in person or by donating below. Your meaningful support is key to sustaining this important facet of Louisiana's past, ensuring it can be enjoyed by future generations."
          descriptionColor="dark-clr"
        />

        <ContentBlock className="content-section pt-0">
          <FormBlock>
            <Section hasNoContainer className="mb-20">
              <ContentBlock>
                <TextBlock
                  text="Choose Donation Amount"
                  type="subheader"
                  color="primary--clr"
                  position="left"
                  className="mb-20"
                />
              </ContentBlock>

              <ContentBlock className="donation-options-container ml-5" cols={12}>
                {donationOptions.map((opt) => (
                  <Button
                    text={opt.label}
                    className={`grey--bg ${selectedAmount === opt.value ? 'selected' : ''}`}
                    size="sm"
                    onClick={() => {
                      setSelectedAmount(opt.value);
                      formik.setFieldValue('amount', opt.value);
                    }}
                  />
                ))}
              </ContentBlock>

              {selectedAmount === '' && (
                <ContentBlock className="mt-20" cols={3}>
                  <InputBlock
                    isRequired
                    label="Other Amount"
                    placeholder="e.g. $ 100"
                    mask="$ 9999"
                    maskChar=" "
                    errorMessage={formik.errors.amount}
                    inputState={`${helper.getInputClasses(formik, 'amount')}`}
                    {...formik.getFieldProps('amount')}
                    onChange={(e) => {
                      // eslint-disable-next-line no-useless-escape
                      const value = e.target.value.replace(/[^\d]/g, '');
                      formik.setFieldValue('amount', +value);
                    }}
                  />
                </ContentBlock>
              )}
            </Section>

            <CustomBlock className="mt-40">
              <Button
                text="Submit"
                className="secondary--bg"
                size="sm"
                onClick={formik.handleSubmit}
              />
            </CustomBlock>
          </FormBlock>
        </ContentBlock>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        centered
        isVisible={isPaymentModalOpen}
        size="md"
        contentHeader="Donation Payment"
        primaryModalActionText=""
        primaryModalActionOnClick={() => { }}
        onHide={() => {
          handleModalClose();
          setIsPaymentByCheck(false);
          setIsPaymentByCheckInstructions(false);
        }}
      >
        <Section hasNoContainer>
          <ContentBlock>
            <TextBlock
              text={`Total Amount: ${helper.currencyFormat(formik.values.amount)}`}
              type="subheader"
              color="primary--clr"
              position="left"
              className="mb-20"
            />
          </ContentBlock>

          {isPaymentByCheckSection && !isPaymentByCheckInstructions && (
            <>
              <ContentBlock>
                <Button
                  text="Pay by Check"
                  style={{ width: '100%' }}
                  size="sm"
                  onClick={() => {
                    setIsPaymentByCheck(true);
                    setIsPaymentByCheckInstructions(true);
                  }}
                  className="secondary--bg"
                />
              </ContentBlock>

              <CustomBlock className="divider-wrapper">
                <hr />
                <TextBlock
                  text="OR"
                  type="subheader"
                  color="primary--clr"
                  position="center"
                  className="divider-text"
                />
              </CustomBlock>
            </>
          )}

          {isPaymentByCheckInstructions && (
            <ContentBlock>
              <TextBlock
                type="description"
                text="Pay To: HSHS"
                color="primary-clr"
                className="fw-600 ml-5 mb-5"
              />

              <TextBlock
                type="description"
                text="PO Box 1909 Albany 70711"
                color="primary-clr"
                className="fw-600 ml-5 mb-5"
              />

              <TextBlock
                type="description"
                text="Thank you for your donation. Once we receive your payment, we will send you an email regarding its confirmation and your membership level will be updated."
                color="primary-clr"
                className="fw-600 ml-5 mb-5 mt-15"
              />
            </ContentBlock>
          )}

          {!isPaymentByCheck && (
            <ContentBlock>
              <PayPalButton
                amount={formik.values.amount}
                onSuccess={(details) => {
                  showAlert({ text: 'Success! Payment went through. Please wait while we record your payment', state: 'success' });
                  setIsLoading();
                  handleModalClose();
                  submitForm(formik.values, JSON.stringify(details));
                }}
                onError={(err) => {
                  showAlert({ text: err.message, state: 'error' });
                }}
                options={{ clientId: constants.PAYPAL_CLIENT_ID }}
                onClick={() => setIsPaymentByCheckSection(false)}
                onCancel={() => setIsPaymentByCheckSection(true)}
              />
            </ContentBlock>
          )}
        </Section>
      </ModalBlock>
    </>
  );
};

export default connect(null, { ...alert.actions })(DonationPage);