/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// COMPONENTS
import { Table } from 'react-bootstrap';
// ICONS
// CUSTOM COMPONENTS
import Button from '../../../../components/Button';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
// CMS COMPONENTS
// ASSETS - BRICK PROJECT TAB
// SERVICES AND HELPERS
import * as brickService from '../../../../services/management/brickService';

const BrickProjectTab = (props) => {
  const { history, setIsLoading } = props;
  const [bricks, setBricks] = useState([]);

  useEffect(() => {
    getUserBricks();
  }, []);

  const getUserBricks = () => {
    setIsLoading(true);

    brickService.getBricks()
      .then((res) => {
        setBricks(res);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ContentBlock className="profile-item-details mt-30">
        <CustomBlock className="header">
          <h4>Purchase History</h4>
        </CustomBlock>
        <CustomBlock className="body">
          {bricks.length === 0 ? (
            <ContentHeader
              subtitle="No brick purchases found."
              subtitleSize="md"
              className="alt-font fw-700 primary--clr m-0 p-0"
            />
          ) : (
            <Table
              striped
              borderless
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <h5 className="text-header--xs pb-0">Item</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Date Ordered</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Line 1</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Line 2</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Line 3</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bricks.map((brick, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{moment(brick.dateOrdered).format('MMMM D, YYYY')}</td>
                    <td>{brick.line1}</td>
                    <td>{brick.line2}</td>
                    <td>{brick.line3}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CustomBlock>
      </ContentBlock>

      <CustomBlock className="content-container--actions">
        <Button
          text="Purchase Brick"
          className="secondary--bg"
          size="sm"
          onClick={() => {
            history.push('/brick-project-form');
          }}
        />
      </CustomBlock>
    </>
  );
};

export default BrickProjectTab;