/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, {
  useEffect,
  useState
} from 'react';
import jwt_decode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { enableBodyScroll } from 'body-scroll-lock';
// ROUTES
import PrivateRoute from './routes/PrivateRoute';
import CMSRoute from './routes/CMSRoute';
import MemberRoute from './routes/MemberRoute';
// CUSTOM COMPONENTS
import ConfirmModal from './components/ConfirmModal';
import AlertToast from './components/AlertToast';
import Overlay from './components/Overlay';
// AUTHENTICATION
import LoginPage from './pages/admin/authentication/LoginPage';
// MANAGEMENT PAGES - SYSTEM
import UserListingPage from './pages/admin/management/user/UserListingPage';
import MemberListingPage from './pages/admin/management/member/MemberListingPage';
import DonationListingPage from './pages/admin/management/donation/DonationListingPage';
import BrickListingPage from './pages/admin/management/brick/BrickListingPage';
import GalleryListingPage from './pages/admin/management/gallery/GalleryListingPage';
import GalleryManagementPage from './pages/admin/management/gallery/GalleryManagementPage';
import NewsletterListingPage from './pages/admin/management/newsletter/NewsletterListingPage';
// PROJECT
// CMS
// -- MENU ITEMS
import MenuItemListingPage from './pages/cms/management/menu-item/MenuItemListingPage';
import MenuItemManagementPage from './pages/cms/management/menu-item/MenuItemManagementPage';
// -- CONTENT PAGES
import PageListingPage from './pages/cms/management/page/PageListingPage';
import PageManagementPage from './pages/cms/management/page/PageManagementPage';
// -- CONTENT SECTIONS
import ContentSectionListingPage from './pages/cms/management/content-section/ContentSectionListingPage';
import ContentSectionManagementPage from './pages/cms/management/content-section/ContentSectionManagementPage';
import BannerManagementPage from './pages/cms/management/content-section/BannerManagementPage';
// ASSETS AND RESOURCES
import AssetsAndResourcesPage from './pages/cms/management/resources/AssetsAndResourcesPage';
// FOOTER ITEMS
import FooterItemListingPage from './pages/cms/management/footer-items/FooterItemListingPage';
// CMS PAGES
import BrickProject from './pages/cms/pages/BrickProject';
import MembershipPage from './pages/cms/pages/MembershipPage';
import RegistrationPage from './pages/cms/pages/RegistrationPage';
import AccountDetailsPage from './pages/cms/pages/AccountDetailsPage';
import DonationPage from './pages/cms/pages/DonationPage';
import GalleryPage from './pages/cms/pages/GalleryPage';
import NewsletterPage from './pages/cms/pages/NewsletterPage';
import GalleryImagePage from './pages/cms/pages/GalleryImagePage';
// BLOGS
import BlogsAndReviewsListingPage from './pages/cms/pages/BlogsAndReviewsListingPage';
import BlogListingPage from './pages/cms/management/blog/BlogListingPage';
import BlogManagementPage from './pages/cms/management/blog/BlogManagementPage';
import DynamicCMSPage from './pages/cms/pages/DynamicCMSPage';
// SERVICES
import * as homeService from './services/cms/homeService';

const initialCMSInfo = {
  appStoreLink: '',
  city: '',
  companyIconPath: '',
  companyLogoPath: '',
  companyName: '',
  emailAddress: '',
  facebookLink: '',
  googleMapLink: '',
  googlePlayStoreLink: '',
  instagramLink: '',
  linkedInLink: '',
  menuItems: [],
  pages: [],
  footerItems: [],
  phoneNumber: '',
  primaryWeekDayHours: '',
  primaryWeekEndHours: '',
  secondaryWeekDayHours: '',
  secondaryWeekEndHours: '',
  state: '',
  streetAddress1: '',
  streetAddress2: '',
  twitterLink: '',
  youtubeLink: '',
  zipCode: '',
};

const Routes = (props) => {
  const { auth } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [cmsInfo, setCmsInfo] = useState(initialCMSInfo);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchCMSInfoAndValidate();
  }, [auth]);

  const fetchCMSInfoAndValidate = async () => {
    setIsLoading(true);
    validateAccess();

    await homeService.getCMSInfo().then((res) => {
      setCmsInfo(res);
    }).catch().finally(() => {
      setIsLoading(false);
    });
  };

  const validateAccess = () => {
    if (auth.authToken !== '') {
      try {
        let decodedToken = jwt_decode(auth.authToken);
        setIsAuthorized(!!decodedToken);
      } catch (ex) {
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    window.scrollTo(0, 0);
    enableBodyScroll(body);
  }, [pathname]);

  return (
    <>
      {
        cmsInfo.googleAnalyticsId && (
          <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${cmsInfo.googleAnalyticsId}`} />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                  function gtag() { dataLayer.push(arguments); }
                  gtag('js', new Date());
                  gtag('config', '${cmsInfo.googleAnalyticsId}');`}
            </script>
          </Helmet>
        )
      }
      <AlertToast />
      <ConfirmModal />
      <>
        {
          isLoading ? <Overlay hasLoader />
            : (
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to="/home"
                />
                <Route
                  path="/login"
                  component={LoginPage}
                />
                {/* CMS PAGES */}
                {/* DYNAMIC CMS ROUTING */}
                {
                  cmsInfo.pages.map((page) => (
                    <CMSRoute
                      path={page.url}
                      component={DynamicCMSPage}
                      title={page.title}
                      dynamicPageId={page.id}
                      isBlog={page.isBlog}
                      cmsInfo={cmsInfo}
                    />
                  ))
                }

                <CMSRoute
                  path="/blogs-and-reviews"
                  component={BlogsAndReviewsListingPage}
                  title="Blogs and Reviews"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/register-user"
                  component={RegistrationPage}
                  title="Registration"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/galleries"
                  component={GalleryPage}
                  title="Gallery"
                  cmsInfo={cmsInfo}
                />

                <CMSRoute
                  path="/gallery-images/:galleryId"
                  component={GalleryImagePage}
                  title="Gallery Images"
                  cmsInfo={cmsInfo}
                />

                <MemberRoute
                  path="/brick-project-form"
                  component={BrickProject}
                  title="Brick Project"
                  cmsInfo={cmsInfo}
                  hasAccess={parseInt(auth.accessLevel, 10) >= 20}
                  accessLevel={auth.accessLevel}
                />

                <MemberRoute
                  path="/donation-form"
                  component={DonationPage}
                  title="Donations"
                  cmsInfo={cmsInfo}
                  hasAccess={parseInt(auth.accessLevel, 10) >= 20}
                  accessLevel={auth.accessLevel}
                />

                <MemberRoute
                  path="/membership-form"
                  component={MembershipPage}
                  title="Membership"
                  cmsInfo={cmsInfo}
                  hasAccess={parseInt(auth.accessLevel, 10) === 20}
                  accessLevel={auth.accessLevel}
                />

                <MemberRoute
                  path="/account-details"
                  component={AccountDetailsPage}
                  title="Account Details"
                  cmsInfo={cmsInfo}
                  hasAccess={
                    parseInt(auth.accessLevel, 10) >= 20 && parseInt(auth.accessLevel, 10) <= 30
                  }
                  accessLevel={auth.accessLevel}
                />

                <CMSRoute
                  path="/newsletters"
                  component={NewsletterPage}
                  title="Newsletters"
                  cmsInfo={cmsInfo}
                />
                {
                  isAuthorized
                    ? (

                      <>
                        {/* USERS */}
                        <PrivateRoute
                          path="/admin/management/users"
                          component={UserListingPage}
                          title="Manage Admins"
                          cmsInfo={cmsInfo}
                        />

                        {/* MEMBERS */}
                        <PrivateRoute
                          path="/admin/management/members"
                          component={MemberListingPage}
                          title="Manage Entries"
                          cmsInfo={cmsInfo}
                        />

                        {/* BRICK PROJECT */}
                        <PrivateRoute
                          path="/admin/management/brick-project"
                          component={BrickListingPage}
                          title="Manage Purchased Bricks"
                          cmsInfo={cmsInfo}
                        />

                        {/* DONATIONS */}
                        <PrivateRoute
                          path="/admin/management/donations"
                          component={DonationListingPage}
                          title="Manage Membership Donations"
                          cmsInfo={cmsInfo}
                        />

                        {/* NEWSLETTERS */}
                        {/* <PrivateRoute
                          path="/admin/management/newsletters"
                          component={NewsletterListingPage}
                          title="Manage Newsletters"
                          cmsInfo={cmsInfo}
                        /> */}

                        {/* CMS */}

                        {/* MENU ITEMS */}
                        <PrivateRoute
                          path="/cms/management/menu-items"
                          component={MenuItemListingPage}
                          title="Manage Menu Items"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item/edit/:menuItemId"
                          component={MenuItemManagementPage}
                          title="Edit Menu Item"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/menu-item"
                          component={MenuItemManagementPage}
                          title="Manage Menu Item"
                          cmsInfo={cmsInfo}
                        />

                        {/* PAGES */}
                        <PrivateRoute
                          path="/cms/management/pages"
                          component={PageListingPage}
                          title="Manage Pages"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page"
                          component={PageManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/page/edit/:pageId"
                          component={PageManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        {/* BLOGS */}
                        <PrivateRoute
                          path="/cms/management/blogs"
                          component={BlogListingPage}
                          title="Manage Blogs"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog"
                          component={BlogManagementPage}
                          title="Manage Page"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/blog/edit/:blogId"
                          component={BlogManagementPage}
                          title="Manage Blog"
                          cmsInfo={cmsInfo}
                        />

                        {/* CONTENT SECTIONS */}
                        <PrivateRoute
                          exact
                          path="/cms/management/content-sections"
                          component={ContentSectionListingPage}
                          title="Manage Content Sections"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/pageId/:pageId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/blogId/:blogId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/content-section/edit/:contentSectionId"
                          component={ContentSectionManagementPage}
                          title="Manage Content Section"
                          cmsInfo={cmsInfo}
                        />

                        {/* BANNER */}
                        <PrivateRoute
                          exact
                          path="/cms/management/banner"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/pageId/:pageId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/blogId/:blogId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/banner/edit/:bannerId"
                          component={BannerManagementPage}
                          title="Banner Management"
                          cmsInfo={cmsInfo}
                        />

                        {/* ASSETS AND RESOURCES */}
                        <PrivateRoute
                          path="/cms/management/assets-and-resources"
                          component={AssetsAndResourcesPage}
                          title="Manage Assets, Links and Resources"
                          cmsInfo={cmsInfo}
                        />

                        {/* FOOTER ITEMS */}
                        <PrivateRoute
                          path="/cms/management/footer-items"
                          component={FooterItemListingPage}
                          title="Footer Items"
                          cmsInfo={cmsInfo}
                        />

                        {/* GALLERY */}
                        <PrivateRoute
                          path="/cms/management/galleries"
                          component={GalleryListingPage}
                          title="Manage Image Galleries"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/create-gallery"
                          component={GalleryManagementPage}
                          title="Manage Gallery"
                          cmsInfo={cmsInfo}
                        />

                        <PrivateRoute
                          exact
                          path="/cms/management/gallery/:galleryId"
                          component={GalleryManagementPage}
                          title="Manage Gallery"
                          cmsInfo={cmsInfo}
                        />

                        {/* NEWSLETTERS */}
                        <PrivateRoute
                          path="/cms/management/newsletters"
                          component={NewsletterListingPage}
                          title="Manage Newsletters"
                          cmsInfo={cmsInfo}
                        />
                      </>
                    )
                    : <Redirect to="/home" />
                }
              </Switch>
            )
        }
      </>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(Routes);