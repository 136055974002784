import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'GalleryImage/';

const getImageById = async (imageId) => axiosInstance.get(`${ENDPOINTORIGIN}GetImageById?imageId=${imageId}`, constants.REQUIRE_INTERCEPTORS);

const getAllImagesByGalleryId = async (galleryId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllImagesByGalleryId?galleryId=${galleryId}`, constants.REQUIRE_INTERCEPTORS);

const addImage = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}AddImage`, model, constants.REQUIRE_INTERCEPTORS);

const updateImage = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateImage`, model, constants.REQUIRE_INTERCEPTORS);

const deleteImage = async (imageId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteImage?imageId=${imageId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getImageById,
  getAllImagesByGalleryId,
  addImage,
  updateImage,
  deleteImage
};