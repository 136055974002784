/* eslint-disable camelcase */
/* eslint-disable default-param-last */
/* eslint-disable no-debugger */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import jwt_decode from 'jwt-decode';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  RefreshToken: '[Refresh Token] Action',
};

const initialAuthState = {
  user: {
    firstName: '',
    lastName: '',
    username: '',
  },
  authToken: '',
  refreshToken: '',
  isAdmin: '',
  accessLevel: 0,
};

export const reducer = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'authToken', 'refreshToken', 'isAdmin', 'accessLevel'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token } = action.payload;
        const userDetail = action.payload;
        const decodedToken = jwt_decode(token);
        delete userDetail.token;

        return {
          user: userDetail,
          authToken: token,
          isAdmin: decodedToken.IsAdmin === 'True',
          refreshToken: userDetail.refreshToken,
          accessLevel: decodedToken.AccessLevel
        };
      }

      case actionTypes.RefreshToken: {
        const { token } = action.payload;
        const userDetail = action.payload;
        const decodedToken = jwt_decode(token);
        delete userDetail.token;

        return {
          user: userDetail,
          authToken: token,
          isAdmin: decodedToken.IsAdmin === 'True',
          refreshToken: userDetail.refreshToken,
          accessLevel: decodedToken.AccessLevel
        };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => ({ type: actionTypes.Login, payload: user }),
  logout: () => ({ type: actionTypes.Logout }),
  refreshToken: (user) => ({ type: actionTypes.RefreshToken, payload: user }),
};