/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
// SERVICES AND HELPERS
import * as brickService from '../../../../services/management/brickService';

const BrickListingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bricks, setBricks] = useState([]);

  useEffect(() => {
    fetchBricks();
  }, []);

  const fetchBricks = () => {
    setIsLoading(true);

    brickService.getAllBricks().then((res) => {
      setBricks(res);
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'orderedBy',
      label: 'Ordered By',
      options: columnOptions,
    },
    {
      name: 'email',
      label: 'Email',
      options: columnOptions,
    },
    {
      name: 'dateOrdered',
      label: 'Date Ordered',
      options: {
        ...columnOptions,
        customBodyRender: (value) => moment(value).format('MMMM D, YYYY'),
      },
    },
    {
      name: 'line1',
      label: 'Line 1',
      options: columnOptions,
    },
    {
      name: 'line2',
      label: 'Line 2',
      options: columnOptions,
    },
    {
      name: 'line3',
      label: 'Line 3',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Purchased Bricks"
                headerSize="lg"
              />

              <CustomBlock>
                <MUIDataTable
                  data={bricks}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

export default BrickListingPage;