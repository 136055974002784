/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PayPalButton } from 'react-paypal-button-v2';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
import ContentSection from '../../../components/ContentSection';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import InputBlock from '../../../components/InputBlock';
import ModalBlock from '../../../components/ModalBlock';
import Overlay from '../../../components/Overlay';
import Section from '../../../components/Section';
import SelectBlock from '../../../components/SelectBlock';
import TextBlock from '../../../components/TextBlock';
// CMS COMPONENTS
// ASSETS - MEMBERSHIP PAGE
import BannerImage from '../../../assets/img/banner-image_membership.jpg';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import * as membershipService from '../../../services/management/membershipService';
import * as alert from '../../../redux/alertToastRedux';
import constants from '../../../constants/constants';

const membershipTierOptions = [
  { value: 'Member', label: 'Member' },
  { value: 'Supporter', label: 'Supporter' },
  { value: 'Sponsor', label: 'Sponsor' },
  { value: 'Patron', label: 'Patron' },
  { value: 'Benefactor', label: 'Benefactor' },
];

const membershipAmountPlaceholderRef = {
  Member: 'e.g. 10 - 49',
  Supporter: 'e.g. 50 - 99',
  Sponsor: 'e.g. 100 - 499',
  Patron: 'e.g. 500 - 999',
  Benefactor: 'e.g. 1000 or more'
};

const initialModel = {
  membershipTier: '',
  amount: '',
  payPalTransactionId: ''
};

const MembershipPage = (props) => {
  const { showAlert, history, auth } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model] = useState(initialModel);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      membershipTier: Yup.string().required().max(100),
      payPalTransactionId: Yup.string().nullable().max(100),
      amount: Yup.number()
        .when('membershipTier', {
          is: (val) => val === 'Member',
          then: Yup.number().required().min(10).max(49),
        })
        .when('membershipTier', {
          is: (val) => val === 'Supporter',
          then: Yup.number().required().min(50).max(99),
        })
        .when('membershipTier', {
          is: (val) => val === 'Sponsor',
          then: Yup.number().required().min(100).max(499),
        })
        .when('membershipTier', {
          is: (val) => val === 'Patron',
          then: Yup.number().required().min(500).max(999),
        })
        .when('membershipTier', {
          is: (val) => val === 'Benefactor',
          then: Yup.number().required().min(1000),
        }),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setIsPaymentModalOpen(true);
      // eslint-disable-next-line no-console
      console.log(submitForm, setSubmitting, values);
    },
  });

  const submitForm = (values, payPalTransaction) => {
    setIsLoading(true);

    membershipService.buyMembership({
      ...values,
      payPalTransactionId: payPalTransaction
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/account-details');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Membership"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="content-wrapper light-grey--sbg">
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Membership Form"
          description="Become a member of the Hungarian Settlement Historical Society to support the preservation of Hungarian heritage and the Hungarian Settlement Museum."
          descriptionColor="dark-clr"
        />

        <ContentBlock className="content-section pt-0">
          <FormBlock>
            <Section hasNoContainer className="mb-20">
              <ContentBlock>
                <TextBlock
                  text="Member Details"
                  type="subheader"
                  color="primary--clr"
                  position="left"
                  className="mb-20"
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  isRequired
                  isDisabled
                  inputState="disabled"
                  label="Full Name"
                  value={auth.user.fullName}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  isRequired
                  label="Membership Tier"
                  value={selectedMembership}
                  options={membershipTierOptions}
                  inputState={`${helper.getInputClasses(formik, 'membershipTier')}`}
                  onChange={(opt) => {
                    opt = opt === null ? [] : opt;
                    setSelectedMembership(opt);
                    formik.setFieldValue('membershipTier', opt.value);
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  isRequired
                  label="Amount"
                  placeholder={selectedMembership && (
                    membershipAmountPlaceholderRef[selectedMembership.value]
                  )}
                  errorMessage={formik.errors.amount}
                  inputState={`${helper.getInputClasses(formik, 'amount')}`}
                  {...formik.getFieldProps('amount')}
                />
              </ContentBlock>
            </Section>

            <CustomBlock className="mt-40">
              <Button
                text="Submit"
                className="secondary--bg"
                size="sm"
                onClick={formik.handleSubmit}
              />
            </CustomBlock>
          </FormBlock>
        </ContentBlock>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        centered
        isVisible={isPaymentModalOpen}
        size="md"
        contentHeader="Membership Payment"
        primaryModalActionText=""
        primaryModalActionOnClick={() => { }}
        onHide={handleModalClose}
      >
        <Section hasNoContainer>
          <ContentBlock>
            <TextBlock
              text={`Total Amount: ${helper.currencyFormat(formik.values.amount)}`}
              type="subheader"
              color="primary--clr"
              position="left"
              className="mb-20"
            />
          </ContentBlock>

          <ContentBlock>
            <PayPalButton
              amount={formik.values.amount}
              onSuccess={(details) => {
                showAlert({ text: 'Success! Payment went through. Please wait while we record your payment', state: 'success' });
                setIsLoading();
                handleModalClose();
                submitForm(formik.values, JSON.stringify(details));
              }}
              onError={(err) => {
                showAlert({ text: err.message, state: 'error' });
              }}
              options={{ clientId: constants.PAYPAL_CLIENT_ID }}
            />
          </ContentBlock>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions })(MembershipPage);