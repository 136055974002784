import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINT = 'FamilyMember/';

const getAllFamilyMembers = async () => axiosInstance.get(`${ENDPOINT}GetAllFamilyMembers`, constants.REQUIRE_INTERCEPTORS);

const getAllRelativeFamilyMembers = async (memberId) => axiosInstance.get(`${ENDPOINT}GetAllRelativeFamilyMembers/?memberId=${memberId}`, constants.REQUIRE_INTERCEPTORS);

const getFamilyMemberById = async (familyMemberId) => axiosInstance.get(`${ENDPOINT}GetFamilyMemberById/${familyMemberId}`, constants.REQUIRE_INTERCEPTORS);

const createFamilyMember = async (model) => axiosInstance.post(`${ENDPOINT}CreateFamilyMember`, model, constants.REQUIRE_INTERCEPTORS);

const deleteFamilyMember = async (familyMemberId) => axiosInstance.delete(`${ENDPOINT}DeleteFamilyMember/?familyMemberId=${familyMemberId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllFamilyMembers,
  getAllRelativeFamilyMembers,
  getFamilyMemberById,
  createFamilyMember,
  deleteFamilyMember
};