// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
// COMPONENTS
// ICONS
import { FiLock, FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
import TextBlock from '../../../components/TextBlock';
// PARTIALS
// import ForgotPasswordBlock from './_partials/ForgotPasswordBlock';
// ASSETS
// import Logo from '../../../assets/img/logo.svg';
import Logo from '../../../assets/img/logo_original-recreation.png';
// SERVICES & HELPERS
import LoginValidator from '../../../helpers/validators/user/LoginValidator';
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';
// REDUX
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

const initialModel = {
  email: '',
  password: ''
};

const LoginPage = (props) => {
  const { showAlert, login, history } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [forgotPasswordBlockVisible, setForgotPasswordBlockVisible] = useState(false);

  const iconSize = 22;

  // const loginUser = (model, setSubmitting) => {
  //   userService.login(model).then((res) => {
  //     login(res);
  //     history.push('/admin/management/users');
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'error' });
  //   }).finally(() => {
  //     setSubmitting(false);
  //   });
  // };

  const loginUser = (model, setSubmitting) => {
    userService.login(model).then((res) => {
      const decodedToken = jwt_decode(res.token);
      login(res);
      if (decodedToken.AccessLevel >= 40) {
        history.push('/admin/management/users');
      } else {
        history.push({
          pathname: '/account-details',
          state: { userId: decodedToken.UserId },
        });
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const formik = useFormik({
    initialValues: initialModel,
    validationSchema: LoginValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      loginUser(values, setSubmitting);
    },
  });

  return (
    <CustomBlock className="main-container">
      <CustomBlock className="authenticate-page login reverse">
        <CustomBlock className="input-section light-grey--sbg" style={{ 'flex-direction': 'column', 'row-gap': '60px' }}>
          <FormBlock>
            <ContentHeader
              title="Account Login"
              headerSize="xl"
            />

            <InputBlock
              id="email"
              label="Username"
              placeholder="Enter username"
              backgroundColor="white--sbg"
              errorMessage={formik.errors.email}
              inputState={`${helper.getInputClasses(formik, 'email')}`}
              {...formik.getFieldProps('email')}
              iconLeft={(
                <FiUser
                  size={iconSize}
                  className="dark-grey--clr"
                />
              )}
            />

            <InputBlock
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              label="Password"
              placeholder="Enter password"
              backgroundColor="white--sbg"
              iconLeft={(
                <FiLock
                  size={iconSize}
                  className="dark-grey--clr"
                />
              )}
              inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
              inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
              {...formik.getFieldProps('password')}
              errorMessage={formik.errors.password}
              inputState={`${helper.getInputClasses(formik, 'password')}`}
            />
            <CustomBlock className="button-container--flex-end">
              <Button
                type="submit"
                text="Sign In"
                className="primary--bg"
                isLoading={formik.isSubmitting}
                isDisabled={formik.isSubmitting || !formik.isValid}
                onClick={formik.handleSubmit}
              />
            </CustomBlock>
          </FormBlock>
          <TextBlock
            isPadded
            text="Not a member? Create an account"
            type="link"
            link="/register-user"
            className="mt-30"
          />
          {/* <TextBlock
            isPadded
            text="Forgot Password?"
            type="link"
            className="mt-30"
            onClick={() => setForgotPasswordBlockVisible(true)}
          /> */}
        </CustomBlock>
        <CustomBlock className="logo-section white--sbg">
          <CustomBlock className="description-block">
            <CustomBlock className="w-100">
              <Image
                source={Logo}
                className="pt-50"
                containerClassName="w-100"
              />
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="footer-block">
            <p className="secondary--clr fw-300">
              Copyright ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              Hungarian Settlement Museum. All rights reserved.
            </p>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertToast.actions })(LoginPage);