import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Gallery/';

const getAllGalleries = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllGalleries`, constants.REQUIRE_INTERCEPTORS);

const getGalleryById = async (galleryId) => axiosInstance.get(`${ENDPOINTORIGIN}GetGalleryById?galleryId=${galleryId}`, constants.REQUIRE_INTERCEPTORS);

const createGallery = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateGallery`, model, constants.REQUIRE_INTERCEPTORS);

const updateGallery = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateGallery`, model, constants.REQUIRE_INTERCEPTORS);

const deleteGallery = async (galleryId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteGallery?galleryId=${galleryId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllGalleries,
  getGalleryById,
  createGallery,
  updateGallery,
  deleteGallery
};