/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoNewspaperOutline } from 'react-icons/io5';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import ModalBlock from '../../../../components/ModalBlock';
import Button from '../../../../components/Button';
import SelectBlock from '../../../../components/SelectBlock';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as newsletterService from '../../../../services/management/newsletterService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const initialNewsletterModel = {
  id: 0,
  name: '',
  month: '',
  year: ''
};

const NewsletterListingPage = (props) => {
  const {
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [newsletters, setNewsletters] = useState([]);
  const [newsletterModel, setNewsletterModel] = useState(initialNewsletterModel);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [file, setFile] = useState([]);
  const [isAddNewsletterModalOpen, setIsAddNewsletterModalOpen] = useState(false);
  const yearOptions = helper.getYearOptions();
  const monthOptions = helper.getMonthOptions();
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchNewsletters();
  }, []);

  const fetchNewsletters = () => {
    setIsLoading(true);

    newsletterService.getAllNewsletters().then((res) => {
      setNewsletters(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: newsletterModel,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      name: Yup.string().required('Newsletter name is required').max(500),
      month: Yup.string().required('Newletter month is required'),
      year: Yup.string().required('Newletter year is required'),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!newsletterModel.id) {
        createNewsletter(values);
      } else {
        updateNewsletter(values);
      }
    },
  });

  const createNewsletter = (model) => {
    setIsLoading(true);
    if (file.length > 0) {
      model = { ...model, document: file.length > 0 ? file[0].file : null };
    }

    newsletterService.createNewsletter(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      setSelectedMonth('');
      setSelectedYear('');
      toggleAddNewsletterModal();
      fetchNewsletters();
    });
  };

  const updateNewsletter = (model) => {
    setIsLoading(true);

    newsletterService.updateNewsletter(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      toggleAddNewsletterModal();
      fetchNewsletters();
    });
  };

  const deleteNewsletter = (newsletterId) => {
    setIsLoading(true);

    newsletterService.deleteNewsletter(newsletterId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
      fetchNewsletters();
    });
  };

  const toggleAddNewsletterModal = () => {
    setIsAddNewsletterModalOpen((prevState) => {
      if (prevState) {
        setNewsletterModel(initialNewsletterModel);
        formik.resetForm();
        setSelectedMonth(null);
        setSelectedYear(null);
      }
      return !prevState;
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                const selected = newsletters[dataIndex];
                toggleAddNewsletterModal();
                setNewsletterModel(selected);
                setSelectedMonth({ label: selected.month, value: selected.month });
                setSelectedYear({ label: selected.year, value: selected.year });
              }}
            />
            <Button
              text="Delete"
              className="danger--bg ml-5"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Newsletter',
                  text: 'Are you sure you want to remove this newsletter?',
                  rightBtnText: 'Confirm',
                  btnAction: () => deleteNewsletter(newsletters[dataIndex].id)
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
    },
    {
      name: 'month',
      label: 'Month',
      options: columnOptions,
    },
    {
      name: 'year',
      label: 'Year',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Newsletters"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Newsletter' : ''}
                primaryButtonIconLeft={<IoNewspaperOutline className={iconColor} size={iconSize} />}
                primaryButtonOnClick={toggleAddNewsletterModal}
              />
              <CustomBlock>
                <MUIDataTable
                  data={newsletters}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } },
                    rowsPerPage: 25,
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* ADD NEWSLETTER MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isAddNewsletterModalOpen}
        size="md"
        contentHeader={newsletterModel.id ? 'Update Newsletter' : 'Add Newsletter'}
        primaryModalActionText={newsletterModel.id ? 'Update' : 'Add'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.handleSubmit}
        onHide={toggleAddNewsletterModal}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <>
            <ContentBlock>
              <InputBlock
                isRequired
                label="Name"
                placeholder="e.g. Newsletter"
                errorMessage={formik.errors.name}
                inputState={`${helper.getInputClasses(formik, 'name')}`}
                {...formik.getFieldProps('name')}
              />
            </ContentBlock>

            <ContentBlock className="mb-15">
              <SelectBlock
                isRequired
                label="Select Month"
                options={monthOptions}
                value={selectedMonth}
                onChange={(selectedOpt) => {
                  selectedOpt = selectedOpt === null ? [] : selectedOpt;
                  setSelectedMonth(selectedOpt);
                  formik.setFieldValue('month', selectedOpt.value);
                }}
              />
            </ContentBlock>

            <ContentBlock className="mb-15">
              <SelectBlock
                isRequired
                label="Select Year"
                options={yearOptions}
                value={selectedYear}
                onChange={(selectedOpt) => {
                  selectedOpt = selectedOpt === null ? [] : selectedOpt;
                  setSelectedYear(selectedOpt);
                  formik.setFieldValue('year', selectedOpt.value);
                }}
              />
            </ContentBlock>

            {formik.values.id === 0 && (
              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Upload Newsletter"
                  subtitle="Upload a document for this newsletter"
                  headerSize="md"
                  subtitleSize="sm"
                  className="alt-font fw-600 secondary--clr pb-15"
                />
                <FileUploadBlock onupdatefiles={setFile} />
              </ContentBlock>
            )}
          </>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(NewsletterListingPage);