/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
// ICONS
// CUSTOM COMPONENTS
// import Section from '../../../components/Section';
// import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
// import TextBlock from '../../../components/TextBlock';
// import Image from '../../../components/Image';
import Banner from '../../../components/Banner';
// import ContentStrip from '../../../components/ContentStrip';
// ASSETS - APP LINKS
// import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
// import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
// HELPERS
import constants from '../../../constants/constants';
// SERVICES

const HomePage = (props) => {
  // const { dynamicPageModel, buttonAction, cmsInfo, history } = props;
  const { dynamicPageModel, buttonAction } = props;
  const [isLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  // const navigateToLink = (link) => {
  //   window.open(link, '_blank');
  // };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock>
        <CustomBlock className="banner-container">
          <Slider {...bannerSliderSettings}>
            {
              banners.map((banner) => (
                <Banner
                  // isDynamicBanner
                  isTitleUppercase
                  isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                  isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                  title={banner.title}
                  imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                  description={banner.description}
                  primaryActionText={banner.primaryButtonText}
                  primaryActionOnClick={() => {
                    buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                  }}
                  secondaryActionText={banner.secondaryButtonText}
                  secondaryActionOnClick={() => {
                    buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                  }}
                />
              ))
            }
          </Slider>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default HomePage;