/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
// ICONS
import { FaDonate } from 'react-icons/fa';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import SelectBlock from '../../../../components/SelectBlock';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import ModalBlock from '../../../../components/ModalBlock';
import FormBlock from '../../../../components/FormBlock';
import Button from '../../../../components/Button';
// SERVICES AND HELPERS
import * as donationService from '../../../../services/management/donationService';
import * as membershipService from '../../../../services/management/membershipService';
import * as helper from '../../../../helpers/helper';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const model = {
  id: 0,
  memberId: '',
  amount: '',
  dateOrdered: '',
  notes: '',
  paymentType: '',
};

const DonationListingPage = (props) => {
  const {
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [donations, setDonations] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMember, setSelectedMember] = useState('');
  const [donationModalVisibility, setDonationModalVisibility] = useState(false);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchDonations();
    fetchMemberOptions();
  }, []);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      amount: Yup.string().required().max(100),
      paymentType: Yup.string().nullable().max(100),
      dateOrdered: Yup.date().required(),
      notes: Yup.string().nullable().max(1000),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id === 0) {
        submitDonation(values);
      } else {
        updateDonation(values);
      }
    },
  });

  const fetchDonations = () => {
    setIsLoading(true);
    donationService.getAllDonations().then((res) => {
      setDonations(res);
    }).catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchMemberOptions = () => {
    setIsLoading(true);

    membershipService.getMemberships().then((res) => {
      const options = res.map((member) => ({
        value: member.id,
        label: `${member.firstName} ${member.lastName} (${member.city}, ${member.state})`
      }));

      setMemberOptions(options);
    }).catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchDonationEdit = (donation) => {
    setIsEditing(true);

    const donationWithParsedDate = {
      ...donation,
      dateOrdered: moment(donation.dateOrdered, 'MM/DD/YYYY hh:mm:ss a').toDate(),
    };

    formik.setValues(donationWithParsedDate);

    setSelectedMember(memberOptions.find((opt) => opt.value === donation.memberId));
    setDonationModalVisibility(true);
  };

  const submitDonation = (values) => {
    setIsLoading(true);
    donationService.submitDonation(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchDonations();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      toggleDonationModal();
    });
  };

  const updateDonation = (values) => {
    setIsLoading(true);
    donationService.updateDonation(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      toggleDonationModal();
      fetchDonations();
    });
  };

  const removeDonation = (donationId) => {
    setIsLoading(true);
    donationService.removeDonation(donationId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
      fetchDonations();
    });
  };

  const toggleDonationModal = () => {
    formik.resetForm();
    setSelectedMember('');
    setIsEditing(false);
    setDonationModalVisibility((prevState) => !prevState);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            {auth.isAdmin && (
              <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
                <Button
                  text="Edit"
                  className="primary--bg ml-0 ml-5"
                  size="xxs"
                  onClick={() => fetchDonationEdit(donations[dataIndex])}
                />
                <Button
                  text="Remove"
                  className="danger--bg ml-5"
                  size="xxs"
                  onClick={() => {
                    const targetDonation = donations[dataIndex];
                    showConfirmModal({
                      title: 'Remove Donation',
                      text: `Are you sure you want to remove ${targetDonation.orderedBy}'s ${helper.currencyFormat(targetDonation.amount)} donation?`,
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        removeDonation(donations[dataIndex].id);
                      }
                    });
                  }}
                />
              </CustomBlock>
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'orderedBy',
      label: 'Ordered By',
      options: columnOptions,
    },
    {
      name: 'dateOrdered',
      label: 'Date Ordered',
      options: {
        ...columnOptions,
        customBodyRender: (value) => moment(value).format('MMMM D, YYYY'),
      },
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{helper.currencyFormat(donations[dataIndex].amount)}</p>
        )
      },
    },
    {
      name: 'paymentType',
      label: 'Payment Type',
      options: columnOptions,
    },
    {
      name: 'notes',
      label: 'Notes',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Membership Donations"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add New Donation' : ''}
                primaryButtonIconLeft={<FaDonate className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => toggleDonationModal()}
              />
              <CustomBlock>
                <MUIDataTable
                  data={donations}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* MEMBER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={donationModalVisibility}
        size="xl"
        contentHeader={isEditing ? 'Edit Donation' : 'Add Donation'}
        primaryModalActionText={isEditing ? 'Save' : 'Add'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => toggleDonationModal()}
      >
        <FormBlock>
          <Section hasNoContainer className="mb-20">
            <ContentBlock cols={6}>
              <SelectBlock
                isRequired
                label="Select Entry"
                options={memberOptions}
                value={selectedMember}
                onChange={(selectedOpt) => {
                  setSelectedMember(selectedOpt);
                  formik.setFieldValue('memberId', selectedOpt.value);
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                isRequired
                label="Payment Date"
                placeholder="Select a date"
                type="date"
                errorMessage={formik.errors.dateOrdered}
                inputState={`${helper.getInputClasses(formik, 'dateOrdered')}`}
                value={formik.values.dateOrdered}
                onChange={(date) => {
                  formik.setFieldValue('dateOrdered', moment(date[0]).format('MM/DD/YYYY hh:mm:ss a'));
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                isRequired
                label="Amount"
                placeholder="e.g. $ 100"
                mask="$ 9999"
                maskChar=" "
                errorMessage={formik.errors.amount}
                inputState={`${helper.getInputClasses(formik, 'amount')}`}
                {...formik.getFieldProps('amount')}
                onChange={(e) => {
                  // eslint-disable-next-line no-useless-escape
                  const value = e.target.value.replace(/[^\d]/g, '');
                  formik.setFieldValue('amount', parseFloat(value));
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Payment Type"
                placeholder="e.g. Check"
                errorMessage={formik.errors.paymentType}
                inputState={`${helper.getInputClasses(formik, 'paymentType')}`}
                value={formik.values.paymentType}
                {...formik.getFieldProps('paymentType')}
              />
            </ContentBlock>

            <ContentBlock>
              <TextAreaBlock
                label="Manual Entry Notes (optional)"
                placeholder="Enter any additional details about this manual donation entry here..."
                className="sm"
                errorMessage={formik.errors.notes}
                inputState={`${helper.getInputClasses(formik, 'notes')}`}
                {...formik.getFieldProps('notes')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  // ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(DonationListingPage);