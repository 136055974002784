import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Donation/';

const getDonations = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetDonations`, constants.REQUIRE_INTERCEPTORS);

const submitDonation = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SubmitDonation`, model, constants.REQUIRE_INTERCEPTORS);

const getAllDonations = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllDonations`, constants.REQUIRE_INTERCEPTORS);

const updateDonation = async (updatedDonation) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateDonation`, updatedDonation, constants.REQUIRE_INTERCEPTORS);

const removeDonation = async (donationId) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveDonation?id=${donationId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getDonations,
  submitDonation,
  getAllDonations,
  updateDonation,
  removeDonation
};