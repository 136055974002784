/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// COMPONENTS
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// ICONS
// CUSTOM COMPONENTS
import AccountDetailsTab from './tabs/AccountDetailsTab';
import Banner from '../../../components/Banner';
import BrickProjectTab from './tabs/BrickProjectTab';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import DontationsTab from './tabs/DontationTab';
import MembershipTab from './tabs/MembershipTab';
// import OnlineStoreTab from './tabs/OnlineStoreTab';
import FamilyMemberTab from './tabs/FamilyMemberTab';
import Overlay from '../../../components/Overlay';
import Section from '../../../components/Section';
import TextBlock from '../../../components/TextBlock';
// CMS COMPONENTS
// ASSETS - ACCOUNT DETAILS PAGE
import BannerImage from '../../../assets/img/banner-image_membership.jpg';
// SERVICES AND HELPERS
import * as membershipService from '../../../services/management/membershipService';
import * as auth from '../../../redux/authRedux';
import * as alertRedux from '../../../redux/alertToastRedux';

const AccountDetailsPage = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [membership, setMembership] = useState({
    firstName: '',
    lastName: '',
    membershipTier: '',
    membershipDateTime: ''
  });

  useEffect(() => {
    getMembership();
  }, []);

  const getMembership = () => {
    setIsLoading(true);
    membershipService.getLoggedInUserMembership().then((res) => {
      setMembership(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Profile"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        <CustomBlock className="content-wrapper">
          <Section>
            <ContentBlock className="pb-30">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <h2 className="profile-header">Welcome, <span>{membership.firstName} {membership.lastName}</span></h2>
              {membership.membershipTier && membership.membershipDateTime ? (
                <h3 className="profile-subheader">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  {membership.membershipTier} <span>Since {moment(membership.membershipDateTime).format('MMMM YYYY')}</span>
                </h3>
              ) : (
                <TextBlock
                  isPadded
                  type="link"
                  text="Join our community today!"
                  color="#037131"
                  link="/membership"
                  className="profile-subheader"
                />
              )}
            </ContentBlock>

            <ContentBlock>
              <Tabs
                id="nav-tabs"
                className="mb-3"
              >
                {/* ACCOUNT SUMMARY TAB */}
                <Tab
                  eventKey="summary"
                  title="Summary"
                  tabClassName="primary-color lg"
                >
                  <AccountDetailsTab
                    {...props}
                    setIsLoading={setIsLoading}
                    membership={membership}
                    setMembership={setMembership}
                  />
                </Tab>

                {/* FAMILY MEMBER TAB */}
                <Tab
                  eventKey="familyMember"
                  title="Family Members"
                  tabClassName="primary-color lg"
                >
                  <FamilyMemberTab
                    {...props}
                    setIsLoading={setIsLoading}
                    membership={membership}
                  />
                </Tab>

                {/* MEMBERSHIP TAB */}
                <Tab
                  eventKey="membership"
                  title="Membership"
                  tabClassName="primary-color lg"
                >
                  <MembershipTab
                    {...props}
                    setIsLoading={setIsLoading}
                  />
                </Tab>

                {/* BRICK PROJECT */}
                <Tab
                  eventKey="brick-project"
                  title="Brick Project"
                  tabClassName="primary-color lg"
                >
                  <BrickProjectTab
                    {...props}
                    setIsLoading={setIsLoading}
                  />
                </Tab>

                {/* DONATIONS */}
                <Tab
                  eventKey="donations"
                  title="Dontations"
                  tabClassName="primary-color lg"
                >
                  <DontationsTab
                    {...props}
                    setIsLoading={setIsLoading}
                  />
                </Tab>

                {/* PURCHASES */}
                {/* <Tab
                  eventKey="online-store"
                  title="Purchases"
                  tabClassName="primary-color lg"
                >
                  <OnlineStoreTab
                    {...props}
                    setIsLoading={setIsLoading}
                  />
                </Tab> */}
              </Tabs>
            </ContentBlock>
          </Section>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertRedux.actions })(AccountDetailsPage);