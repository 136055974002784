/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
import Button from '../../../../components/Button';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ModalBlock from '../../../../components/ModalBlock';
import FormBlock from '../../../../components/FormBlock';
import SelectBlock from '../../../../components/SelectBlock';
import InputBlock from '../../../../components/InputBlock';
import ContentHeader from '../../../../components/ContentHeader';
// CMS COMPONENTS
// ASSETS - FAMILY MEMBER TAB
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as familyMemberServivce from '../../../../services/management/familyMemberService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alertRedux from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const FamilyMemberTab = (props) => {
  const {
    membership,
    showAlert,
    setIsLoading,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [memberOptions, setMemberOptions] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [relativeFamilyMembers, setRelativeFamilyMembers] = useState([]);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState([]);
  const [isAddFamilyMemberModal, setIsAddFamilyMemberModal] = useState(false);

  useEffect(() => {
    getFamilyMembers();
  }, []);

  useEffect(() => {
    if (membership.id > 0) {
      getMemberOptions();
      getRelativeFamilyMembers(membership.id);
    }
  }, [membership]);

  const getFamilyMembers = () => {
    setIsLoading(true);

    familyMemberServivce.getAllFamilyMembers().then((res) => {
      setFamilyMembers(res);
    }).catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRelativeFamilyMembers = (memberId) => {
    setIsLoading(true);

    familyMemberServivce.getAllRelativeFamilyMembers(memberId).then((res) => {
      setRelativeFamilyMembers(res);
    }).catch()
      .finally(() => setIsLoading(false));
  };

  const getMemberOptions = () => {
    setIsLoading(true);

    membershipService.getMemberships().then((res) => {
      const options = res.map((member) => ({
        value: member.id,
        label: `${member.firstName} ${member.lastName} (${member.city}, ${member.state})`
      }));
      const ignoreMemberIds = [...(familyMembers || []).map((member) => member.memberId), membership.id];
      const filteredOptions = options.filter((opt) => !ignoreMemberIds.includes(opt.value));

      setMemberOptions(filteredOptions);
    }).catch()
      .finally(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: familyMembers,
    validationSchema: Yup.object().shape({
      userId: Yup.number(),
      relativeId: Yup.number(),
      relation: Yup.string().required('Family member relationship is required').max(250),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      createFamilyMember(values);
    },
  });

  const createFamilyMember = (model) => {
    setIsLoading(true);
    model = { ...model, relativeId: selectedFamilyMember.value };

    familyMemberServivce.createFamilyMember(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      toggleIsAddFamilyMemberModal();
      getFamilyMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteFamilyMember = (familyMemberId) => {
    setIsLoading(true);

    familyMemberServivce.deleteFamilyMember(familyMemberId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getFamilyMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const toggleIsAddFamilyMemberModal = () => setIsAddFamilyMemberModal((prevState) => !prevState);

  return (
    <>
      <ContentBlock className="profile-item-details mt-30">
        <CustomBlock className="header">
          <h4>Added Members</h4>
        </CustomBlock>

        <CustomBlock className="body">
          {familyMembers.length === 0 ? (
            <ContentHeader
              subtitle="No family members found."
              subtitleSize="md"
              className="alt-font fw-700 primary--clr m-0 p-0"
            />
          ) : (
            <Table
              responsive
              striped
              borderless
              hover
            >
              <thead>
                <tr>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Actions</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Name</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Location</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Relationship</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {familyMembers.length > 0 && (
                  familyMembers.map((familyMember) => (
                    <tr>
                      <td valign="middle">
                        <Button
                          text="Delete"
                          className="danger--bg ml-0"
                          size="xxs"
                          onClick={() => {
                            showConfirmModal({
                              title: 'Remove Family Member',
                              text: `Are you sure you want to remove ${familyMember.firstName} ${familyMember.lastName} from your family members?`,
                              rightBtnText: 'Confirm',
                              btnAction: () => deleteFamilyMember(familyMember.id)
                            });
                          }}
                        />
                      </td>
                      <td valign="middle">{`${familyMember.firstName} ${familyMember.lastName}`}</td>
                      <td valign="middle">{`${familyMember.city}, ${familyMember.state}`}</td>
                      <td valign="middle">{familyMember.relation}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
          <CustomBlock className="content-container--actions">
            <Button
              text="Add Family Member"
              className="secondary--bg"
              size="sm"
              onClick={() => toggleIsAddFamilyMemberModal()}
            />
          </CustomBlock>
        </CustomBlock>
      </ContentBlock>

      <ContentBlock className="profile-item-details mt-60">
        <CustomBlock className="header">
          <h4>Connected Members</h4>
        </CustomBlock>

        <CustomBlock className="body">
          {relativeFamilyMembers.length === 0 ? (
            <ContentHeader
              subtitle="When members include you in their family, they will be displayed in this section."
              subtitleSize="md"
              className="alt-font fw-700 primary--clr m-0 p-0"
            />
          ) : (
            <Table
              responsive
              striped
              borderless
              hover
            >
              <thead>
                <tr>
                  {/* <th valign="middle">
                    <h5 className="text-header--xs pb-0">Actions</h5>
                  </th> */}
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Added By</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Name</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Location</h5>
                  </th>
                  <th valign="middle">
                    <h5 className="text-header--xs pb-0">Relationship</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {relativeFamilyMembers.length > 0 && (
                  relativeFamilyMembers.map((member) => (
                    <tr>
                      {/* <td valign="middle">
                        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
                          <Button
                            isDisabled
                            text="Add"
                            className="secondary--bg ml-0"
                            size="xs"
                          // onClick={() => handlePendingRelationAction()}
                          />
                          <Button
                            isDisabled
                            text="Remove"
                            className="danger--bg ml-10"
                            size="xs"
                          // onClick={() => handlePendingRelationAction()}
                          />
                        </CustomBlock>
                      </td> */}
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      <td valign="middle">{member.fromName} ({member.fromCity}, {member.fromState})</td>
                      <td valign="middle">{`${member.firstName} ${member.lastName}`}</td>
                      <td valign="middle">{`${member.city}, ${member.state}`}</td>
                      <td valign="middle">{member.relation}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
        </CustomBlock>
      </ContentBlock>

      {/* ADD FAMILY MEMBER MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isAddFamilyMemberModal}
        size="md"
        contentHeader="New Family Member"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.handleSubmit}
        onHide={toggleIsAddFamilyMemberModal}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <ContentBlock className="mb-15">
            <SelectBlock
              isRequired
              label="Members"
              placeholder="Select family member"
              options={memberOptions}
              value={selectedFamilyMember}
              onChange={setSelectedFamilyMember}
            />
          </ContentBlock>

          <ContentBlock>
            <InputBlock
              isRequired
              label="Relationship"
              placeholder="e.g. Brother"
              errorMessage={formik.errors.relation}
              inputState={`${helper.getInputClasses(formik, 'relation')}`}
              {...formik.getFieldProps('relation')}
            />
          </ContentBlock>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertRedux.actions,
  ...confirmModal.actions
})(FamilyMemberTab);