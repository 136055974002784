/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
import { IoLogoTiktok } from 'react-icons/io5';
import {
  RiFacebookFill,
  RiInstagramLine,
  RiTwitterFill,
  RiLinkedinFill,
  RiYoutubeLine,
} from 'react-icons/ri';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import Image from './Image';
import TextBlock from './TextBlock';
import InputBlock from './InputBlock';
// ASSETS
import AppStoreBadge from '../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../assets/img/google-play-badge.svg';

const FooterBlock = (props) => {
  const {
    history,
    to,
    phoneNumber,
    emailAddress,
    imageSource,
    backgroundColor,
    bottomFooterBackgroundColor,
    subheaderColor,
    subheaderClassName,
    companyName,
    copyrightTextColor,
    footerHeaderTextColor,
    footerItems,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    appStoreLink,
    googlePlayLink,
    facebookLink,
    instagramLink,
    twitterLink,
    linkedInLink,
    youtubeLink,
    tiktokLink,
    // primaryActionOnClick,
    // secondaryActionOnClick,
    hasSocialMediaIconColor,
    hasEmailSignUp,
    hasContactBlock
  } = props;

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_self');
  };

  const callPhoneNumber = (tel) => {
    window.open(`tel:${tel}`, '_self');
  };

  return (
    <CustomBlock className={`footer-block ${backgroundColor}`}>
      <CustomBlock className="main-footer">
        <CustomBlock className="left-block">
          <Image
            to={to}
            source={`${process.env.REACT_APP_API_URL}Attachments/${imageSource}`}
          />
        </CustomBlock>

        <CustomBlock className="center-block">
          <TextBlock
            isUppercase
            isPadded
            type="subheader"
            text="Quick Links"
            color={footerHeaderTextColor}
          />

          <CustomBlock className="links">
            <ul>
              {
                footerItems.map((item) => (
                  <li>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        // eslint-disable-next-line no-unused-expressions
                        (item.to).includes('https' || 'http') ? navigateToLink(item.to) : history.push(item.to);
                      }}
                    >
                      {item.label}
                    </a>
                  </li>
                ))
              }
            </ul>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="right-block">
          {
            hasEmailSignUp
            && (
              <CustomBlock className={`${appStoreLink || googlePlayLink !== '' ? 'mb-60' : ''}`}>
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Get the latest deals"
                  color={footerHeaderTextColor}
                />

                <InputBlock
                  placeholder="Enter your email"
                  inputRightButtonText="Sign Up"
                  inputRightButtonBackgroundColor={`lighter-blue--sbg ${iconAppearance === 'circle' ? 'circle' : ''}`}
                  inputRightButtonTextColor="info--clr"
                  backgroundColor="white--sbg"
                  className={`${iconAppearance === 'circle' ? 'circle' : ''}`}
                />
              </CustomBlock>
            )
          }

          {
            hasContactBlock && (!appStoreLink || !googlePlayLink)
            && (
              <CustomBlock className="contact-block">
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Contact Us"
                  color={footerHeaderTextColor}
                />

                <CustomBlock className="pt-15">
                  <Button
                    text="Message Us"
                    className="secondary--bdr"
                    size="sm"
                    onClick={() => {
                      sendEmail(emailAddress);
                    }}
                  />

                  <Button
                    text="Call Us"
                    className="secondary--bg"
                    size="sm"
                    onClick={() => {
                      callPhoneNumber(phoneNumber);
                    }}
                  />
                </CustomBlock>
              </CustomBlock>
            )
          }

          {
            (appStoreLink || googlePlayLink)
            && (
              <CustomBlock className="app-download-block">
                <TextBlock
                  isPadded
                  isUppercase
                  type="subheader"
                  text="Download Our App"
                  color={subheaderColor}
                  className={subheaderClassName}
                />

                <CustomBlock className="app-download-badges">
                  {
                    appStoreLink
                    && (
                      <Image
                        source={AppStoreBadge}
                        onClick={() => navigateToLink(appStoreLink)}
                      />
                    )
                  }

                  {
                    googlePlayLink
                    && (
                      <Image
                        source={GooglePlayBadge}
                        onClick={() => navigateToLink(googlePlayLink)}
                      />
                    )
                  }
                </CustomBlock>
              </CustomBlock>
            )
          }

        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="social-media-block">
        <CustomBlock className="social-icons">
          {
            facebookLink
            && (
              <button
                onClick={() => navigateToLink(facebookLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'facebook--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <RiFacebookFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
          {
            instagramLink
            && (
              <button
                onClick={() => navigateToLink(instagramLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'instagram--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <RiInstagramLine className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
          {
            twitterLink
            && (
              <button
                onClick={() => navigateToLink(twitterLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'twitter--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <RiTwitterFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
          {
            linkedInLink
            && (
              <button
                onClick={() => navigateToLink(linkedInLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'linkedin--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <RiLinkedinFill className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
          {
            youtubeLink
            && (
              <button
                onClick={() => navigateToLink(youtubeLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'danger--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <RiYoutubeLine className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
          {
            tiktokLink
            && (
              <button
                onClick={() => navigateToLink(tiktokLink)}
                className={`social-icon ${hasSocialMediaIconColor ? 'dark--bg' : socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
              >
                <IoLogoTiktok className={hasSocialMediaIconColor ? 'white--clr' : socialIconColor} size={iconSize} />
              </button>
            )
          }
        </CustomBlock>
      </CustomBlock>

      <CustomBlock className={`bottom-footer ${bottomFooterBackgroundColor}`}>
        <p className={`copyright ${copyrightTextColor}`}>{`Copyright © ${new Date().getFullYear()} ${companyName}. All Rights Reserved.`}</p>
        <button
          className={`copyright ml-5 ${copyrightTextColor}`}
          onClick={() => {
            navigateToLink('https://primtek.net/');
          }}
        >
          Developed by Primtek.
        </button>
      </CustomBlock>
    </CustomBlock>
  );
};

FooterBlock.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  backgroundColor: PropTypes.string,
  bottomFooterBackgroundColor: PropTypes.string,
  imageSource: PropTypes.string,

  // CONTACT PROPS
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: PropTypes.string,
  subheaderClassName: PropTypes.string,

  // APP STORE PROPS
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedInLink: PropTypes.string,
  youtubeLink: PropTypes.string,
  tiktokLink: PropTypes.string,

  // FOOTER PROPS
  footerHeaderTextColor: PropTypes.string.isRequired,

  // BOTTOM FOOTER PROPS
  companyName: PropTypes.string.isRequired,
  copyrightTextColor: PropTypes.string,

  // FUNCTION PROPS
  // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: PropTypes.bool,
  hasEmailSignUp: PropTypes.bool,
  hasContactBlock: PropTypes.bool,

  footerItems: PropTypes.array,
};

FooterBlock.defaultProps = {
  // MAIN PROPS
  to: '#',
  backgroundColor: 'white--sbg',
  bottomFooterBackgroundColor: 'lightest-grey--sbg',
  imageSource: '',

  // CONTACT PROPS
  phoneNumber: '',
  emailAddress: '',

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: 'secondary--clr',
  subheaderClassName: '',

  // APP STORE PROPS
  appStoreLink: '',
  googlePlayLink: '',

  // SOCIAL MEDIA PROPS
  iconSize: 27,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'secondary--sbg',
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  linkedInLink: '',
  youtubeLink: '',
  tiktokLink: '',

  // BOTTOM FOOTER PROPS
  copyrightTextColor: 'primary--clr',

  // FUNCTION PROPS
  // primaryActionOnClick: null,
  // secondaryActionOnClick: null,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: false,
  hasEmailSignUp: false,
  hasContactBlock: true,

  footerItems: [],
};

export default FooterBlock;