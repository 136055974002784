import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Membership/';

const getMemberships = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetMemberships`, constants.REQUIRE_INTERCEPTORS);

const createMembership = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateUserMembership`, model, constants.REQUIRE_INTERCEPTORS);

const updateUserMembership = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateUserMembership`, model, constants.REQUIRE_INTERCEPTORS);

const buyMembership = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}BuyMembership`, model, constants.REQUIRE_INTERCEPTORS);

const getLoggedInUserMembership = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetLoggedInUserMembership`, constants.REQUIRE_INTERCEPTORS);

const getMemberInfo = async (memberId) => axiosInstance.get(`${ENDPOINTORIGIN}getMemberInfo?memberId=${memberId}`, constants.REQUIRE_INTERCEPTORS);

const deleteMember = async (memberId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteMember?id=${memberId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getMemberships,
  createMembership,
  updateUserMembership,
  buyMembership,
  getLoggedInUserMembership,
  getMemberInfo,
  deleteMember
};