import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Newsletter/';

const getAllNewsletters = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllNewsletters`, constants.REQUIRE_INTERCEPTORS);

const getNewsletterById = async (newsletterId) => axiosInstance.get(`${ENDPOINTORIGIN}GetNewsletterById?newsletterId=${newsletterId}`, constants.REQUIRE_INTERCEPTORS);

const createNewsletter = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateNewsletter`, model, constants.REQUIRE_INTERCEPTORS);

const updateNewsletter = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateNewsletter`, model, constants.REQUIRE_INTERCEPTORS);

const deleteNewsletter = async (newsletterId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteNewsletter?newsletterId=${newsletterId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllNewsletters,
  getNewsletterById,
  createNewsletter,
  updateNewsletter,
  deleteNewsletter
};