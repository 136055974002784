/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import Section from '../../../components/Section';
import TextHeader from '../../../components/TextHeader';
import ModalBlock from '../../../components/ModalBlock';
// CMS COMPONENTS
// ASSETS - GALLERY IMAGE PAGE
// SERVICES AND HELPERS
import * as galleryService from '../../../services/management/galleryService';
import * as alert from '../../../redux/alertToastRedux';

const GalleryImagePage = (props) => {
  const { showAlert } = props;
  const { galleryId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [clickedImage, setClickedImage] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const bannerImage = 'https://images.pexels.com/photos/296649/pexels-photo-296649.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

  useEffect(() => {
    if (galleryId) {
      fetchGallery();
    }
  }, []);

  const fetchGallery = () => {
    setIsLoading(true);
    galleryService.getGalleryById(galleryId).then((res) => {
      setGallery(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleImageClick = (image) => {
    setClickedImage(image);
    toggleImageModal();
  };

  const toggleImageModal = () => setIsImageModalOpen((prevState) => !prevState);

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Photo Gallery"
        isDynamicBanner
        imageSource={bannerImage}
      />

      <CustomBlock className="content-container--padded">
        <Section className="pb-80">
          <ContentBlock>
            <TextHeader
              title={gallery.name}
              size="xl"
              className="pt-40 pb-80"
            />

            <CustomBlock className="gallery-image-grid">
              {gallery.galleryImages && (
                gallery.galleryImages.map((image) => (
                  <CustomBlock className="gallery-image" onClick={() => handleImageClick(image)}>
                    <CustomBlock className="image" style={{ background: `url(${process.env.REACT_APP_API_URL}Attachments${image.imageFilePath})  no-repeat center/cover`, width: 'auto', height: '300px' }} />
                    <p className="caption">{image.subtitle}</p>
                  </CustomBlock>
                ))
              )}
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* EXPAND IMAGE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isImageModalOpen}
        size="lg"
        primaryModalActionText="Close"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={toggleImageModal}
        onHide={toggleImageModal}
        isSecondaryActionHidden
      >
        {clickedImage && (
          <Section hasNoContainer>
            <img alt={clickedImage.subtitle} src={`${process.env.REACT_APP_API_URL}Attachments${clickedImage.imageFilePath}`} />
            <p className="caption">{clickedImage.subtitle}</p>
          </Section>
        )}
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions })(GalleryImagePage);