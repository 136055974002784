/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENTS
import { FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
import Section from '../../../components/Section';
import ContentSection from '../../../components/ContentSection';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import InputBlock from '../../../components/InputBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import LoginPasswordRecoveryModal from '../../../components/LoginPasswordRecoveryModal';
// ASSETS - REGISTRATION PAGE
import BannerImage from '../../../assets/img/banner-image_membership.jpg';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import * as membershipService from '../../../services/management/membershipService';
// REDUX
import * as authRedux from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

const initialModel = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  password: '',
  confirmPassword: '',
};

const RegistrationPage = (props) => {
  const { showAlert, history, login, iconSize } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model] = useState(initialModel);
  // const [loginPasswordConfirmVisible, setLoginPasswordConfirmVisible] = useState(false);
  // USER REGISTRATION
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  // LOGIN
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      firstName: Yup.string().required().max(100),
      lastName: Yup.string().required().max(100),
      phoneNumber: Yup.string().required(),
      businessName: Yup.string().max(500),
      emailAddress: Yup.string().required().max(100).email(),
      addressLine1: Yup.string().required().max(100),
      addressLine2: Yup.string().nullable().max(100),
      city: Yup.string().required().max(100),
      state: Yup.string().required().max(100),
      zipCode: Yup.string().required().max(100),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Minimum password length must be at least 8 characters')
        .max(100),
      confirmPassword: Yup.string()
        .required('Please confirm your new password')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      submitForm(values);
    },
  });

  const submitForm = (values) => {
    setIsLoading(true);
    membershipService.createMembership(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/account-details');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
    // setForgotPasswordBlockVisible(false);
    // setRecoveryEmail('');
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Registration"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <ContentSection
        isTextOnly
        hasRoundCorners
        title="Welcome to the Historical Society!"
        description="Become a member of the Hungarian Settlement Historical Society to support the preservation of Hungarian heritage and the Hungarian Settlement Museum. By joining, you'll enjoy benefits such as attending quarterly meetings, managing your account information with a membership profile, and connecting with other registered family members."
        descriptionColor="dark-clr"
        primaryActionText="Learn More"
        primaryActionTo="/membership"
        secondaryActionText="Already a Member? Sign in"
        secondaryActionOnClick={handleLoginModalOpen}
      />

      <ContentBlock className="content-section pb-100">
        <FormBlock>
          <ContentBlock>
            <TextBlock
              text="Registration Form"
              type="header"
              color="primary--clr"
              position="left"
              className="mt-40 mb-20"
            />
          </ContentBlock>

          {/* GENERAL INFORMATION */}
          <Section hasNoContainer className="mb-20">
            <ContentBlock>
              <TextBlock
                text="Contact Information"
                type="subheader"
                color="primary--clr"
                position="left"
                className="mb-10"
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="First Name"
                placeholder="e.g. John"
                errorMessage={formik.errors.firstName}
                inputState={`${helper.getInputClasses(formik, 'firstName')}`}
                {...formik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Last Name"
                placeholder="e.g. Smith"
                errorMessage={formik.errors.lastName}
                inputState={`${helper.getInputClasses(formik, 'lastName')}`}
                {...formik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Phone Number"
                placeholder="e.g. (555) 555-5555"
                mask="(999) 999-9999"
                errorMessage={formik.errors.phoneNumber}
                inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                {...formik.getFieldProps('phoneNumber')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Email Address"
                placeholder="e.g. address@email.com"
                errorMessage={formik.errors.emailAddress}
                inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                {...formik.getFieldProps('emailAddress')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Password"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Enter password"
                inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
                inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
                errorMessage={formik.errors.password}
                inputState={`${helper.getInputClasses(formik, 'password')}`}
                {...formik.getFieldProps('password')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Confirm Password"
                type={passwordConfirmVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                inputRightButtonText={passwordConfirmVisible ? 'Hide' : 'Show'}
                inputRightButtonOnClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
                errorMessage={formik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(formik, 'confirmPassword')}`}
                {...formik.getFieldProps('confirmPassword')}
              />
            </ContentBlock>
          </Section>

          {/* ADDRESS INFORMATION */}
          <Section hasNoContainer className="mb-20">
            <ContentBlock>
              <TextBlock
                text="Address Information"
                type="subheader"
                color="primary--clr"
                position="left"
                className="mb-10"
              />
            </ContentBlock>

            <Section hasNoContainer className="pl-0 pr-0">
              <ContentBlock cols={3}>
                <InputBlock
                  label="Business Name (optional)"
                  placeholder="e.g. Hungarian Settlement"
                  errorMessage={formik.errors.businessName}
                  inputState={`${helper.getInputClasses(formik, 'businessName')}`}
                  {...formik.getFieldProps('businessName')}
                />
              </ContentBlock>
            </Section>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Street Address"
                placeholder="e.g. 123 Main St."
                errorMessage={formik.errors.addressLine1}
                inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                {...formik.getFieldProps('addressLine1')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                label="Apt/Suite/Unit (optional)"
                placeholder="e.g. Apt 1"
                errorMessage={formik.errors.addressLine2}
                inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                {...formik.getFieldProps('addressLine2')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="City"
                placeholder="e.g. Albany"
                errorMessage={formik.errors.city}
                inputState={`${helper.getInputClasses(formik, 'city')}`}
                {...formik.getFieldProps('city')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="State"
                placeholder="e.g. LA"
                errorMessage={formik.errors.state}
                inputState={`${helper.getInputClasses(formik, 'state')}`}
                {...formik.getFieldProps('state')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="ZIP/Postcode"
                placeholder="e.g. 70711"
                errorMessage={formik.errors.zipCode}
                inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                {...formik.getFieldProps('zipCode')}
              />
            </ContentBlock>
          </Section>

          <CustomBlock className="content-container--actions">
            <Button
              text="Submit"
              className="secondary--bg"
              size="sm"
              onClick={formik.handleSubmit}
            />
          </CustomBlock>
        </FormBlock>
      </ContentBlock>

      {/* LOGIN MODAL */}
      <LoginPasswordRecoveryModal
        isVisible={isLoginModalOpen}
        setIsVisible={setIsLoginModalOpen}
        showAlert={showAlert}
        history={history}
        login={login}
        iconSize={iconSize}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  { ...authRedux.actions, ...alertToast.actions }
)(RegistrationPage);