/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  Accordion,
  Card,
  Table
} from 'react-bootstrap';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import { FaChevronDown, FaDonate } from 'react-icons/fa';
import { GiBrickWall } from 'react-icons/gi';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { MdCardMembership, MdFamilyRestroom } from 'react-icons/md';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import TextBlock from '../../../../components/TextBlock';
import FormBlock from '../../../../components/FormBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as helper from '../../../../helpers/helper';
import * as alert from '../../../../redux/alertToastRedux';
// import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const initialModel = {
  id: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  notes: '',
  // password: '',
  // confirmPassword: '',
  isActive: true,
};

const MemberListingPage = (props) => {
  const {
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [nonMembers, setNonMembers] = useState([]);
  const [memberToEdit, setMemberToEdit] = useState(initialModel);
  const [memberInfo, setMemberInfo] = useState([]);
  const [donations, setDonations] = useState([]);
  const [bricks, setBricks] = useState([]);
  const [detailsModalVisibility, setDetailsModalVisibility] = useState(false);
  const [memberModalVisibility, setMemberModalVisibility] = useState(false);
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [model] = useState(initialModel);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchMembers();
  }, []);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      // id: Yup.number(),
      firstName: Yup.string().required().max(100),
      lastName: Yup.string().nullable().required().max(100),
      phoneNumber: Yup.string().nullable().required(),
      businessName: Yup.string().nullable().max(500),
      emailAddress: Yup.string().nullable().required().max(100)
        .email(),
      addressLine1: Yup.string().nullable().required().max(100),
      addressLine2: Yup.string().nullable().max(100),
      city: Yup.string().nullable().required().max(100),
      state: Yup.string().nullable().required().max(100),
      zipCode: Yup.string().nullable().required().max(100),
      notes: Yup.string().nullable().max(1000),
      isActive: Yup.bool(),
      // password: Yup.string()
      //   .required('Password is required')
      //   .min(8, 'Minimum password length must be at least 8 characters')
      //   .max(100),
      // confirmPassword: Yup.string()
      //   .required('Please confirm your new password')
      //   .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id === 0) {
        const tempPassword = `MemberP@$$_${values.lastName.toLowerCase()}`;
        const updatedValues = { ...values, password: tempPassword, confirmPassword: tempPassword };
        submitForm(updatedValues);
      } else {
        updateForm(values);
      }
    },
  });

  const fetchMembers = () => {
    setIsLoading(true);
    membershipService.getMemberships().then((res) => {
      const activeMemberships = [];
      const nonActiveMemberships = [];

      res.forEach((item) => {
        if (item.hasActiveMembership) {
          activeMemberships.push(item);
        } else {
          nonActiveMemberships.push(item);
        }
      });

      setMembers(activeMemberships);
      setNonMembers(nonActiveMemberships);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchMemberDetails = (memberId) => {
    setIsLoading(true);

    membershipService.getMemberInfo(memberId).then((res) => {
      setMemberInfo(res);
      setDonations(res.donations);
      setBricks(res.bricks);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      setDetailsModalVisibility(true);
    });
  };

  const fetchMemberEdit = (memberId) => {
    setIsLoading(true);

    membershipService.getMemberInfo(memberId).then((res) => {
      setMemberToEdit(res);
      formik.setValues(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      toggleMemberModal(true);
    });
  };

  const submitForm = (values) => {
    setIsLoading(true);
    membershipService.createMembership(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      toggleMemberModal();
    });
  };

  const updateForm = (values) => {
    setIsLoading(true);
    membershipService.updateUserMembership(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      toggleMemberModal();
    });
  };

  const deleteMember = (memberId) => {
    setIsLoading(true);
    membershipService.deleteMember(memberId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const toggleDetailsModal = () => setDetailsModalVisibility((prevState) => !prevState);

  const toggleMemberModal = () => {
    setMemberModalVisibility((prevState) => {
      if (prevState) {
        setMemberToEdit(initialModel);
        formik.resetForm();
      }
      return !prevState;
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const memberColumns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="info--bg ml-0"
              size="xxs"
              onClick={() => fetchMemberDetails(members[dataIndex].id)}
            />
            <Button
              text="Edit"
              className="primary--bg ml-0 ml-5"
              size="xxs"
              onClick={() => fetchMemberEdit(members[dataIndex].id)}
            />
            {auth.isAdmin && (
              <Button
                text="Delete"
                className="danger--bg ml-5"
                size="xxs"
                onClick={() => {
                  showConfirmModal({
                    title: 'Remove Member',
                    text: `Are you sure you want to remove ${members[dataIndex].firstName} ${members[dataIndex].lastName}?`,
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      deleteMember(members[dataIndex].id);
                    }
                  });
                }}
              />
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: columnOptions,
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: columnOptions,
    },
    {
      name: 'emailAddress',
      label: 'Email Address',
      options: columnOptions,
    },
    // {
    //   name: 'address',
    //   label: 'Address',
    // },
    // {
    //   name: 'membershipTier',
    //   label: 'Membership',
    //   options: columnOptions,
    // },
    // {
    //   name: 'isActive',
    //   label: 'Is Active?',
    // },
    {
      name: 'membershipDateTime',
      label: 'Membership Renewed On',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { membershipDateTime } = members[dataIndex];

          const today = moment();
          const dateRenewed = moment(membershipDateTime);
          const daysSinceRenewal = today.diff(dateRenewed, 'days');

          return (
            <CustomBlock>
              <p>{dateRenewed.format('MMMM DD, YYYY')}</p>
              <p className="secondary-lighter--clr fw-500 size-xxs">{`${daysSinceRenewal} days ago`}</p>
            </CustomBlock>
          );
        }
      },
    },
  ];

  const nonMemberColumns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="info--bg ml-0"
              size="xxs"
              onClick={() => fetchMemberDetails(nonMembers[dataIndex].id)}
            />
            <Button
              text="Edit"
              className="primary--bg ml-0 ml-5"
              size="xxs"
              onClick={() => fetchMemberEdit(nonMembers[dataIndex].id)}
            />
            {auth.isAdmin && (
              <Button
                text="Delete"
                className="danger--bg ml-5"
                size="xxs"
                onClick={() => {
                  showConfirmModal({
                    title: 'Remove Member',
                    text: `Are you sure you want to remove ${nonMembers[dataIndex].firstName} ${nonMembers[dataIndex].lastName}?`,
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      deleteMember(nonMembers[dataIndex].id);
                    }
                  });
                }}
              />
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: columnOptions,
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: columnOptions,
    },
    {
      name: 'emailAddress',
      label: 'Email Address',
      options: columnOptions,
    },
    // {
    //   name: 'address',
    //   label: 'Address',
    // },
    // {
    //   name: 'membershipTier',
    //   label: 'Membership',
    //   options: columnOptions,
    // },
    // {
    //   name: 'isActive',
    //   label: 'Is Active?',
    // },
  ];

  const AccordionBlock = ({ title, icon, eventKey, children }) => (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <h5>
          {icon}
          {' '}
          {title}
        </h5>
        <FaChevronDown />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">

        {/* MEMBER LISTING */}
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <CustomBlock className="manage-entities-header mb-60">
                <ContentHeader
                  title="All Entries"
                  headerSize="lg"
                  primaryButtonText={auth.isAdmin ? 'Add New Entry' : ''}
                  primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                  primaryButtonOnClick={() => toggleMemberModal()}
                />
              </CustomBlock>

              <CustomBlock>
                <ContentHeader
                  title="Active Memberships"
                  headerSize="md"
                />
                <MUIDataTable
                  data={members}
                  columns={memberColumns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } },
                    rowsPerPage: 50,
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>

        {/* NON MEMBER LISTING */}
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Inactive Memberships"
                headerSize="lg"
              // primaryButtonText={auth.isAdmin ? 'Add New Entry' : ''}
              // primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
              // primaryButtonOnClick={() => toggleMemberModal()}
              />

              <CustomBlock>
                <MUIDataTable
                  data={nonMembers}
                  columns={nonMemberColumns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } },
                    rowsPerPage: 50,
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {memberInfo && (
        <ModalBlock
          hasCloseAction
          centered
          isVisible={detailsModalVisibility}
          size="xl"
          contentHeader={`${memberInfo.firstName} ${memberInfo.lastName}`}
          primaryModalActionText=""
          primaryModalActionOnClick={() => { }}
          onHide={() => toggleDetailsModal()}
        >
          <Section hasNoContainer>

            <Accordion defaultActiveKey="0" className="p-0">

              <AccordionBlock
                title="Membership"
                icon={<MdCardMembership />}
                eventKey="0"
              >
                {!memberInfo.membershipTier ? (
                  <p>No membership found.</p>
                ) : (
                  <Table
                    striped
                    borderless
                    size="sm"
                  >
                    <thead>
                      <tr>
                        <th>
                          <h5 className="text-header--xs pb-0">Item</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Date Ordered</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Membership</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Amount</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{moment(memberInfo.membershipDateTime).format('MMMM D, YYYY')}</td>
                        <td>{memberInfo.membershipTier}</td>
                        <td>{helper.currencyFormat(memberInfo.amount)}</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </AccordionBlock>

              <AccordionBlock
                title="Bricks"
                icon={<GiBrickWall />}
                eventKey="1"
              >
                {bricks.length === 0 ? (
                  <p>No brick purchases found.</p>
                ) : (
                  <Table
                    striped
                    borderless
                    size="sm"
                  >
                    <thead>
                      <tr>
                        <th>
                          <h5 className="text-header--xs pb-0">Item</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Date Ordered</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Line 1</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Line 2</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Line 3</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bricks.map((brick, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{moment(brick.dateOrdered).format('MMMM D, YYYY')}</td>
                          <td>{brick.line1}</td>
                          <td>{brick.line2}</td>
                          <td>{brick.line3}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </AccordionBlock>

              <AccordionBlock
                title="Donations"
                icon={<FaDonate />}
                eventKey="2"
              >
                {donations.length === 0 ? (
                  <p>No donations found.</p>
                ) : (
                  <Table
                    striped
                    borderless
                    size="sm"
                  >
                    <thead>
                      <tr>
                        <th>
                          <h5 className="text-header--xs pb-0">Item</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Date Ordered</h5>
                        </th>
                        <th>
                          <h5 className="text-header--xs pb-0">Amount</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {donations.map((donation, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{moment(donation.dateOrdered).format('MMMM D, YYYY')}</td>
                          <td>{helper.currencyFormat(donation.amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </AccordionBlock>

              <AccordionBlock
                title="Purchases"
                icon={<RiShoppingCart2Fill />}
                eventKey="3"
              >
                No Purchases found.
              </AccordionBlock>

              <AccordionBlock
                title="Family Contacts"
                icon={<MdFamilyRestroom />}
                eventKey="4"
              >
                No Family Contacts found.
              </AccordionBlock>

            </Accordion>
          </Section>
        </ModalBlock>
      )}

      {/* MEMBER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={memberModalVisibility}
        size="xl"
        contentHeader={memberToEdit.id === 0 ? 'Add Member' : 'Update Member'}
        primaryModalActionText={memberToEdit.id === 0 ? 'Add' : 'Update'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.submitForm}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => toggleMemberModal()}
      >
        <FormBlock>
          <Section hasNoContainer className="mb-20">
            <ContentBlock>
              <CheckboxBlock
                label="Is Member Active?"
                id="isActive"
                {...formik.getFieldProps('isActive')}
                isChecked={formik.values.isActive}
              />
            </ContentBlock>
          </Section>

          <Section hasNoContainer className="mb-20">
            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="First Name"
                placeholder="e.g. John"
                errorMessage={formik.errors.firstName}
                inputState={`${helper.getInputClasses(formik, 'firstName')}`}
                {...formik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="Last Name"
                placeholder="e.g. Smith"
                errorMessage={formik.errors.lastName}
                inputState={`${helper.getInputClasses(formik, 'lastName')}`}
                {...formik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="Phone Number"
                placeholder="e.g. (555) 555-5555"
                mask="(999) 999-9999"
                errorMessage={formik.errors.phoneNumber}
                inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                {...formik.getFieldProps('phoneNumber')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="Email Address"
                placeholder="e.g. address@email.com"
                errorMessage={formik.errors.emailAddress}
                inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                {...formik.getFieldProps('emailAddress')}
              />
            </ContentBlock>
          </Section>

          <Section hasNoContainer className="mb-20">
            <ContentBlock>
              <TextBlock
                text="Address Information"
                type="subheader"
                color="primary--clr"
                position="left"
                className="mb-10"
              />
            </ContentBlock>

            <Section className="pl-0 pr-0">
              <ContentBlock cols={4}>
                <InputBlock
                  label="Business Name (optional)"
                  placeholder="e.g. Hungarian Settlement"
                  errorMessage={formik.errors.businessName}
                  inputState={`${helper.getInputClasses(formik, 'businessName')}`}
                  {...formik.getFieldProps('businessName')}
                />
              </ContentBlock>
            </Section>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="Street Address"
                placeholder="e.g. 123 Main St."
                errorMessage={formik.errors.addressLine1}
                inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                {...formik.getFieldProps('addressLine1')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                label="Apt/Suite/Unit (optional)"
                placeholder="e.g. Apt 1"
                errorMessage={formik.errors.addressLine2}
                inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                {...formik.getFieldProps('addressLine2')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="City"
                placeholder="e.g. Albany"
                errorMessage={formik.errors.city}
                inputState={`${helper.getInputClasses(formik, 'city')}`}
                {...formik.getFieldProps('city')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="State"
                placeholder="e.g. LA"
                errorMessage={formik.errors.state}
                inputState={`${helper.getInputClasses(formik, 'state')}`}
                {...formik.getFieldProps('state')}
              />
            </ContentBlock>

            <ContentBlock cols={4}>
              <InputBlock
                isRequired
                label="ZIP/Postcode"
                placeholder="e.g. 70711"
                errorMessage={formik.errors.zipCode}
                inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                {...formik.getFieldProps('zipCode')}
              />
            </ContentBlock>

            <ContentBlock>
              <TextAreaBlock
                label="Member Notes (optional)"
                placeholder="Enter any additional details about the member here..."
                className="sm"
                errorMessage={formik.errors.notes}
                inputState={`${helper.getInputClasses(formik, 'notes')}`}
                {...formik.getFieldProps('notes')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  // ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(MemberListingPage);