import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Users/';

const login = async (user) => axiosInstance.post(`${ENDPOINTORIGIN}Authenticate`, user, constants.REQUIRE_INTERCEPTORS);

const register = async (user) => axiosInstance.post(`${ENDPOINTORIGIN}Register`, user, constants.REQUIRE_INTERCEPTORS);

const getUserById = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetUser?id=${userId}`, constants.REQUIRE_INTERCEPTORS);

const getAllUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllUsers`, constants.REQUIRE_INTERCEPTORS);

const updateUser = async (user) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateUser`, user, constants.REQUIRE_INTERCEPTORS);

const changeUserPassword = async (newPassword, oldPassword) => axiosInstance.put(`${ENDPOINTORIGIN}ChangeUserPassword?password=${newPassword}&oldPassword=${oldPassword}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteUser = async (userId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteUser?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const sendPasswordRecoveryEmail = async (userEmail) => axiosInstance.get(`${ENDPOINTORIGIN}SendPasswordRecoveryEmail?userEmail=${userEmail}`, constants.REQUIRE_INTERCEPTORS);

const resetPassword = async (data) => axiosInstance.put(`${ENDPOINTORIGIN}ResetPassword`, data, constants.REQUIRE_INTERCEPTORS);

export {
  login,
  register,
  getUserById,
  getAllUsers,
  updateUser,
  changeUserPassword,
  deleteUser,
  sendPasswordRecoveryEmail,
  resetPassword
};