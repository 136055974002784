/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PayPalButton } from 'react-paypal-button-v2';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentSection from '../../../components/ContentSection';
import TextBlock from '../../../components/TextBlock';
import FormBlock from '../../../components/FormBlock';
import InputBlock from '../../../components/InputBlock';
import Overlay from '../../../components/Overlay';
import ModalBlock from '../../../components/ModalBlock';
// CMS COMPONENTS
// ASSETS - BRICK PROJECT
import BannerImage from '../../../assets/img/banner-image_brick-project.png';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import * as brickService from '../../../services/management/brickService';
import * as alert from '../../../redux/alertToastRedux';
import constants from '../../../constants/constants';

const initialModel = {
  line1: '',
  line2: '',
  line3: '',
  amount: 100,
  payPalTransactionId: ''
};

const BrickProject = (props) => {
  const { showAlert, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model] = useState(initialModel);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  useEffect(() => {
  }, []);

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      line1: Yup.string().required().max(20),
      line2: Yup.string().max(20),
      line3: Yup.string().max(20),
      amount: Yup.number(),
      payPalTransactionId: Yup.string().nullable().max(100),
    }),
    enableReinitialize: true,
    onSubmit: () => setIsPaymentModalOpen(true),
  });

  const submitForm = (values, payPalTransactionId) => {
    setIsLoading(true);
    brickService.submitBrick({
      ...values,
      payPalTransactionId
    })
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
        history.push('/account-details');
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="The Brick Project"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="content-wrapper light-grey--sbg">
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Brick Project Form"
          description="The Brick Project not only offers a special way to honor loved ones, friends, organizations, or businesses, but also supports the preservation of Hungarian heritage and the museum itself. This heartfelt tribute creates lasting connections and memories while contributing to a meaningful cause."
          descriptionColor="dark-clr"
        />

        <ContentBlock className="content-section pt-0">
          <FormBlock>
            <Section hasNoContainer className="mb-20">
              <ContentBlock>
                <TextBlock
                  text="Monogram Details"
                  type="subheader"
                  color="primary--clr"
                  position="left"
                  className="mb-10"
                />
              </ContentBlock>

              <ContentBlock className="mt-20" cols={3}>
                <InputBlock
                  isRequired
                  label="Line 1"
                  placeholder="Line 1 (20 char limit)"
                  errorMessage={formik.errors.line1}
                  inputState={`${helper.getInputClasses(formik, 'line1')}`}
                  {...formik.getFieldProps('line1')}
                />
              </ContentBlock>

              <ContentBlock className="mt-20" cols={3}>
                <InputBlock
                  isRequired
                  label="Line 2"
                  placeholder="Line 2 (20 char limit)"
                  errorMessage={formik.errors.line2}
                  inputState={`${helper.getInputClasses(formik, 'line2')}`}
                  {...formik.getFieldProps('line2')}
                />
              </ContentBlock>

              <ContentBlock className="mt-20" cols={3}>
                <InputBlock
                  isRequired
                  label="Line 3"
                  placeholder="Line 3 (20 char limit)"
                  errorMessage={formik.errors.line3}
                  inputState={`${helper.getInputClasses(formik, 'line3')}`}
                  {...formik.getFieldProps('line3')}
                />
              </ContentBlock>
            </Section>

            <CustomBlock className="mt-40">
              <Button
                text="Submit"
                className="secondary--bg"
                size="sm"
                onClick={formik.handleSubmit}
              />
            </CustomBlock>
          </FormBlock>
        </ContentBlock>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        centered
        isVisible={isPaymentModalOpen}
        size="md"
        contentHeader="Brick Payment"
        primaryModalActionText=""
        primaryModalActionOnClick={() => { }}
        onHide={handleModalClose}
      >
        <Section hasNoContainer>
          <ContentBlock>
            <TextBlock
              text={`Total Amount: ${helper.currencyFormat(formik.values.amount)}`}
              type="subheader"
              color="primary--clr"
              position="left"
              className="mb-20"
            />
          </ContentBlock>

          <ContentBlock>
            <PayPalButton
              amount={formik.values.amount}
              onSuccess={(details) => {
                showAlert({ text: 'Success! Payment went through. Please wait while we record your payment', state: 'success' });
                setIsLoading();
                handleModalClose();
                submitForm(formik.values, JSON.stringify(details));
              }}
              onError={(err) => {
                showAlert({ text: err.message, state: 'error' });
              }}
              options={{ clientId: constants.PAYPAL_CLIENT_ID }}
            />
          </ContentBlock>
        </Section>
      </ModalBlock>
    </>
  );
};

export default connect(null, { ...alert.actions })(BrickProject);