/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import FormBlock from '../../../../components/FormBlock';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
import CustomBlock from '../../../../components/CustomBlock';
// CMS COMPONENTS
// ASSETS - ACCOUNT DETAILS TAB
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as membershipService from '../../../../services/management/membershipService';
import * as alertRedux from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const AccountDetailsTab = (props) => {
  const {
    membership,
    showAlert,
    setIsLoading
  } = props;

  const formik = useFormik({
    initialValues: membership,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      firstName: Yup.string().required().max(100),
      lastName: Yup.string().required().max(100),
      phoneNumber: Yup.string().required(),
      emailAddress: Yup.string().required().max(100).email(),
      businessName: Yup.string().nullable().max(500),
      addressLine1: Yup.string().required().max(100),
      addressLine2: Yup.string().nullable().max(100),
      city: Yup.string().required().max(100),
      state: Yup.string().required().max(100),
      zipCode: Yup.string().required().max(100),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      submitForm(values);
    },
  });

  const submitForm = (values) => {
    setIsLoading(true);
    membershipService.updateUserMembership(values).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <ContentBlock className="profile-item-details mt-30">
      <div className="header">
        <h4 className="primary-color">Account Details</h4>
      </div>

      <div className="body">
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="First Name"
                placeholder={membership.firstName}
                errorMessage={formik.errors.firstName}
                inputState={`${helper.getInputClasses(formik, 'firstName')}`}
                {...formik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Last Name"
                placeholder={membership.lastName}
                errorMessage={formik.errors.lastName}
                inputState={`${helper.getInputClasses(formik, 'lastName')}`}
                {...formik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Phone Number"
                placeholder={membership.phoneNumber}
                mask="(999) 999-9999"
                errorMessage={formik.errors.phoneNumber}
                inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                {...formik.getFieldProps('phoneNumber')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                isDisabled
                isRequired
                label="Email"
                placeholder={membership.emailAddress}
                errorMessage={formik.errors.emailAddress}
                inputState="disabled"
                {...formik.getFieldProps('emailAddress')}
              />
            </ContentBlock>

            <Section className="pl-0 pr-0">
              <ContentBlock cols={6}>
                <InputBlock
                  label="Business Name (optional)"
                  placeholder="e.g. Hungarian Settlement"
                  errorMessage={formik.errors.businessName}
                  inputState={`${helper.getInputClasses(formik, 'businessName')}`}
                  {...formik.getFieldProps('businessName')}
                />
              </ContentBlock>
            </Section>

            <ContentBlock cols={3}>
              <InputBlock
                isRequired
                label="Street Address"
                placeholder={membership.addressLine1}
                errorMessage={formik.errors.addressLine1}
                inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                {...formik.getFieldProps('addressLine1')}
              />
            </ContentBlock>

            <ContentBlock cols={3}>
              <InputBlock
                label="Apt/Suite/Unit (optional)"
                placeholder={membership.addressLine2}
                errorMessage={formik.errors.addressLine2}
                inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                {...formik.getFieldProps('addressLine2')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="City"
                placeholder={membership.city}
                errorMessage={formik.errors.city}
                inputState={`${helper.getInputClasses(formik, 'city')}`}
                {...formik.getFieldProps('city')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="State"
                placeholder={membership.state}
                errorMessage={formik.errors.state}
                inputState={`${helper.getInputClasses(formik, 'state')}`}
                {...formik.getFieldProps('state')}
              />
            </ContentBlock>

            <ContentBlock cols={2}>
              <InputBlock
                isRequired
                label="ZIP/Postcode"
                placeholder={membership.zipCode}
                errorMessage={formik.errors.zipCode}
                inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                {...formik.getFieldProps('zipCode')}
              />
            </ContentBlock>
          </Section>

          <CustomBlock className="content-container--actions">
            <Button
              text="Save"
              className="secondary--bg"
              size="sm"
              onClick={formik.handleSubmit}
            />
          </CustomBlock>
        </FormBlock>
      </div>
    </ContentBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertRedux.actions
})(AccountDetailsTab);