/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// COMPONENTS
import { Table } from 'react-bootstrap';
// ICONS
// CUSTOM COMPONENTS
import Button from '../../../../components/Button';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
// CMS COMPONENTS
// ASSETS - MEMBERSHIP TAB
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as helper from '../../../../helpers/helper';

const MembershipTab = (props) => {
  const { history, setIsLoading } = props;
  const [membership, setMembership] = useState([]);

  useEffect(() => {
    getUserMembership();
  }, []);

  const getUserMembership = () => {
    setIsLoading(true);

    membershipService.getLoggedInUserMembership()
      .then((res) => {
        setMembership(res);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ContentBlock className="profile-item-details mt-30">
        <CustomBlock className="header">
          <h4>Membership Details</h4>
        </CustomBlock>
        <CustomBlock className="body">
          {!membership.membershipTier ? (
            <ContentHeader
              subtitle="No membership found."
              subtitleSize="md"
              className="alt-font fw-700 primary--clr m-0 p-0"
            />
          ) : (
            <Table
              striped
              borderless
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <h5 className="text-header--xs pb-0">Item</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Date Ordered</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Membership</h5>
                  </th>
                  <th>
                    <h5 className="text-header--xs pb-0">Amount</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{moment(membership.membershipDateTime).format('MMMM d, yyyy')}</td>
                  <td>{membership.membershipTier}</td>
                  <td>{helper.currencyFormat(membership.amount)}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </CustomBlock>
      </ContentBlock>

      {!membership.membershipTier && (
        <CustomBlock className="content-container--actions">
          <Button
            text="Become a Member"
            className="secondary--bg"
            size="sm"
            onClick={() => {
              history.push('/membership-form');
            }}
          />
        </CustomBlock>
      )}
    </>
  );
};

export default MembershipTab;